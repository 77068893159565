import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IImage, IStore } from '../../../../types/storeTypes'
import { useFormSession } from '../../../../hooks/useFormSession'
import { Loader } from '../../../loader'
import { useOfferItem } from '../../../../hooks/useOfferItem'
import { getCurrency } from '../../../../utils/getCurrency'
import { DefaultButton } from '../../../defaultButton'
import { useOfferDocs } from '../../../../hooks/useOfferDocs'
import { ClientShortInfoBlock } from '../../../formBlocks/clientShortInfoBlock'
import parse from 'html-react-parser'
import { IconClose } from '../../../../images/IconClose'
import { PhotoUploader } from '../../photoUploader'
import { IUploadSignatureData, useOfferAgreements } from '../../../../hooks/useOfferAgreements'
import { IconPdf } from '../../../../images/IconPdf'
import { IconDraw } from '../../../../images/IconDraw'
import { IconFromPhone } from '../../../../images/IconFromPhone'
import { SignatureModal } from '../../../modals/signatureModal'
import { LoadSignatureViaMobileQrCode } from '../../../modals/QRCodeModals/loadSignatureViaMobileQrCode'
import { v4 as uuidv4 } from 'uuid'
import { LOCAL_ACCESS_TOKEN, SESSION_TOKEN } from '../../../../utils/consts'
import { MessageModal } from '../../../modals/messageModal'
import { useGetConsignedReturnStepData } from '../../../../hooks/useGetConsignedReturnStepData'
import { useConsignedReturn } from '../../../../hooks/useConsignedReturn'

const tabs = [{ value: 'return', title: 'Consignment Return' }]

const defaultClient = {
  address1: '',
  address2: '',
  birthday: '',
  country: '',
  email: '',
  first_name: '',
  full_name: '',
  gender: '',
  id: 0,
  id_number: '',
  id_type: '',
  last_name: '',
  municipality: '',
  ns_lead_source: 0,
  phone_no: '',
  postal_code: '',
  province: '',
  height_type: '',
  height_1: 0,
  height_2: 0,
}

const clientRequiredFields = [
  'first_name',
  'last_name',
  'email',
  'phone_no',
  'country',
  'province',
  'municipality',
  'address1',
  'postal_code',
  'email',
]

const allRequiredFields = [...clientRequiredFields]

export const SignatureStep = () => {
  const { t, i18n } = useTranslation()
  const currentStep = useSelector((store: IStore) => store.currentStep)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const client = useSelector((store: IStore) => store.client)
  const agreements = useSelector((store: IStore) => store.agreements)
  const consignmentReturn = useSelector((store: IStore) => store.consignmentReturn)
  const offerDicts = useSelector((store: IStore) => store.offerDicts)
  const { returnItems } = consignmentReturn
  const { stepsLoading, changeCurrentStep, getConfirmationStepData } = useGetConsignedReturnStepData()
  const { saveConsignmentConversionSession } = useFormSession()
  const { getOfferItemTitle } = useOfferItem()
  const [saveLoading, setSaveLoading] = useState(false)
  const { loadReturnPdf, loadPdfLoading } = useOfferDocs()
  const [selectedTab, setSelectedTab] = useState(tabs[0].value)
  const { uploadSignature, deleteSignature, deleteSignatureLoading, uploadSignatureLoading } = useOfferAgreements()
  const [showSignatureModal, setShowSignatureModal] = useState(false)
  const [showQrCode, setShowQrCode] = useState(false)
  const [token, setToken] = useState<string>(uuidv4())
  const [clientInfo, setStateClientInfo] = useState<IStore['client']>(defaultClient)
  const [buyer, setBuyer] = useState<number>(0)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const { setReturnInProgress } = useConsignedReturn()
  const sessionData = useSelector((store: IStore) => store.sessionData)
  const sessionToken = sessionData.sessionId || sessionStorage.getItem(SESSION_TOKEN) || ''

  const shouldCheckClient = true

  useEffect(() => {
    const newClient = { ...client }
    if (client.identity && client.identity.height) {
      newClient.height_type = client.identity.height.measure
      newClient.height_1 =
        client.identity.height.measure === 'Metric'
          ? client.identity.height.value.cm || 0
          : client.identity.height.value.ft || 0
      newClient.height_2 = client.identity.height.value.in || 0
    }
    setStateClientInfo(newClient)
  }, [client])

  useEffect(() => {
    if (appointmentInfo.intermediate_save && appointmentInfo.intermediate_save.buyer_id) {
      setBuyer(appointmentInfo.intermediate_save.buyer_id)
    } else if (appointmentInfo && appointmentInfo.buyer_id) {
      setBuyer(appointmentInfo.buyer_id)
    }
  }, [appointmentInfo, appointmentInfo.buyer_id])

  const checkForErrors = () => {
    let fields = allRequiredFields
    const errors = fields
      .map((field) => {
        if (field === 'buyer') return !buyer ? field : ''
        else if (field === 'email') {
          if (!clientInfo.email && (!clientInfo.phone_no || clientInfo.phone_no.length < 5)) return field
        } else if (field === 'phone_no') {
          if (!clientInfo.email && (!clientInfo.phone_no || clientInfo.phone_no.length < 5)) return field
        } else if (field === 'postal_code') {
          if (!clientInfo.postal_code && clientInfo.country !== 'HK') return field
        } else if (field === 'province') {
          if (!clientInfo.province && ['CA', 'US'].includes(clientInfo.country)) return field
        } else if (!clientInfo[field as keyof IStore['client']]) return field

        return ''
      })
      .filter((error) => !!error)

    if (errors.length > 0) {
      setFormErrors(errors)
      return false
    }

    return true
  }

  useEffect(() => {
    if (clientInfo.id) checkForErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInfo])

  const onSave = async () => {
    setSaveLoading(true)
    if (shouldCheckClient && !checkForErrors()) {
      setOpenErrorModal(true)
      setSaveLoading(false)
      return null
    }
    const nextStep = 4
    const data = {
      items: consignmentReturn.returnItems.map((item) => (item.id ? item.id : 0)),
    }
    await setReturnInProgress(data, async () => {
      await getConfirmationStepData()
      await saveConsignmentConversionSession({ currentStep: nextStep })
      changeCurrentStep(nextStep)
    })
    setSaveLoading(false)
  }

  const onBack = () => {
    changeCurrentStep(currentStep - 1)
  }

  useEffect(() => {
    if (!showQrCode) setToken(uuidv4())
  }, [showQrCode])

  const onSignedImagesUploaded = (images: IImage[]) => {
    const data: IUploadSignatureData = {
      files: images.map((image) => {
        return { url: image.url, name: image.name }
      }),
      agreement: selectedTab as IUploadSignatureData['agreement'],
      signature: '',
    }
    uploadSignature(data)
  }

  const returnAmount = useMemo(() => {
    return returnItems.reduce((a, b) => {
      return a + (b.price ? +b.price : 0)
    }, 0)
  }, [returnItems])

  const signatureFile = useMemo(() => {
    return agreements.return_form
  }, [agreements])

  const signatureAmount = useMemo(() => {
    return getCurrency(returnAmount, consignmentReturn.currency)
  }, [returnAmount, consignmentReturn.currency])

  const onPdfPrint = () => {
    const data = {
      items: consignmentReturn.returnItems.map((item) => (item.id ? item.id : 0)),
    }
    loadReturnPdf(data)
  }

  return stepsLoading.includes(4) ||
    stepsLoading.includes(2) ||
    returnItems.length <= 0 ||
    !offerDicts.offerDictsUploaded ? (
    <div className='w-full h-[70vh] flex justify-center items-center'>
      <Loader className='h-[100px]' />
    </div>
  ) : (
    <>
      <div className='flex flex-col gap-6'>
        <div className='flex w-full gap-2 justify-start overflow-auto custom_scroll pb-2'>
          {tabs.map((tab) => {
            return (
              <button
                key={`items-tab-${tab.value}`}
                type='button'
                onClick={() => setSelectedTab(tab.value)}
                className={`flex justify-center items-center text-lg font-medium rounded-lg py-2 h-9 w-[280px] min-w-[200px] ${tab.value === selectedTab ? 'text-white bg-green-700' : ' text-zinc-600 bg-white'}`}
              >
                {t(tab.title)}
              </button>
            )
          })}
        </div>

        <div className=''>
          <div className='text-base font-medium text-zinc-500 mb-2 flex justify-between px-3'>
            <div className=' w-[60%]'>
              <span className='mr-3'>#</span>
              {t('Item Descriptions')}
            </div>
            <div className='flex justify-end w-[100px] min-w-[60px]'>{t('Price')}</div>
          </div>

          <div className=' rounded-lg bg-white flex flex-col divide-y divide-zinc-200 max-h-[50vh] overflow-y-auto custom_scroll'>
            {returnItems.map((item, index) => {
              return (
                <div key={`${item.type}-${index}`} className=' text-zinc-700 flex justify-between items-center px-3'>
                  <div className='flex items-center w-[60%] min-h-[68px]'>
                    <div className='text-[22px] pb-1'>{index + 1}</div>
                    <div className=' text-base font-medium px-3 line-clamp-2'>{getOfferItemTitle(item)}</div>
                  </div>
                  <div className='flex'>
                    <div className='text-lg font-medium flex items-center justify-end w-[100px] min-w-[60px]'>
                      {item.price ? getCurrency(item.price, consignmentReturn.currency) : ''}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className='w-full flex flex-col md:flex-row justify-between items-start gap-6 md:gap-1 lg:gap-10 desktop:gap-20'>
          <div className='flex flex-col gap-4 w-full md:w-auto'>
            <ClientShortInfoBlock
              client={clientInfo}
              setClientInfo={setStateClientInfo}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              requiredFields={shouldCheckClient ? allRequiredFields : []}
              isSignature
              disableEdit={!shouldCheckClient}
              isGlobalSave
              buyer={buyer}
            />
          </div>

          <div className='flex flex-col gap-6 px-4 w-full'>
            <div className='flex justify-end text-lg font-medium text-zinc-700'>
              {t('Total Offer Amount') + ' ' + getCurrency(returnAmount, consignmentReturn.currency)}
            </div>

            <div className='text-base font-normal text-zinc-700 flex justify-end'>
              {parse(t('signature_return_agreement'))}
            </div>

            {signatureFile ? (
              <div className='flex w-full items-center justify-end gap-4 lg:gap-12'>
                <div className='text-base font-normal text-zinc-700 whitespace-nowrap flex justify-end'>
                  {t('Signed copy')}
                </div>
                <div className='flex-wrap gap-3 flex justify-end relative'>
                  {deleteSignatureLoading && (
                    <div className='absolute z-10 bg-zinc-500 opacity-20 rounded inset-0 flex justify-center items-center'>
                      <Loader className={`h-8 w-8 ml-2`} />
                    </div>
                  )}
                  <div className='flex items-center gap-2'>
                    <a href={signatureFile} target='_blank' rel='noreferrer' className='flex max-w-full max-h-full'>
                      <IconPdf classname='h-[60px] w-auto' />
                    </a>
                    <div className='text-xs text-zinc-500 capitalize'>
                      {t(tabs.find((tab) => tab.value === selectedTab)?.title || '')}
                    </div>
                    <button
                      type='button'
                      onClick={() => deleteSignature(selectedTab as IUploadSignatureData['agreement'])}
                      disabled={deleteSignatureLoading}
                    >
                      <IconClose />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className='flex w-full items-center justify-end gap-4 lg:gap-12'>
                  <div className='text-base font-normal text-zinc-700 whitespace-nowrap flex justify-end'>
                    {t('Signature')}
                  </div>
                  <div className='flex gap-2 items-center relative'>
                    {uploadSignatureLoading && (
                      <div className='absolute z-10 bg-zinc-500 opacity-20 rounded inset-0 flex justify-center items-center'>
                        <Loader className={`h-8 w-8 ml-2`} />
                      </div>
                    )}

                    <div className='flex gap-2'>
                      <div className='w-[120px] hidden lg:flex'></div>
                      <button
                        type='button'
                        className='identification-image-upload-button !text-base lg:!text-lg !w-[136px] lg:!w-[150px]'
                        onClick={() => setShowSignatureModal(true)}
                      >
                        <IconDraw />
                        {t('Add signature')}
                      </button>
                      <button
                        type='button'
                        className='identification-image-upload-button !text-base lg:!text-lg !w-[136px] lg:!w-[150px]'
                        onClick={() => setShowQrCode(true)}
                      >
                        <IconFromPhone />
                        {t('From mobile')}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`flex w-full items-center  ${signatureFile ? ' justify-end gap-4 lg:gap-12' : 'justify-end gap-4 lg:gap-12'}`}
                >
                  <div className='text-base font-normal text-zinc-700 whitespace-nowrap flex justify-end'>
                    {t('Upload signed copy')}
                  </div>
                  <div className='flex gap-2 relative flex-col items-start lg:flex-row lg:items-center'>
                    {uploadSignatureLoading && (
                      <div className='absolute z-10 bg-zinc-500 opacity-20 rounded inset-0 flex justify-center items-center'>
                        <Loader className={`h-8 w-8 ml-2`} />
                      </div>
                    )}
                    <DefaultButton
                      title={t('Print')}
                      onClick={onPdfPrint}
                      classname='grey_button !w-[136px] lg:!w-[120px] !h-[50px] lg:!h-[53.6px]'
                      loading={loadPdfLoading}
                      disabled={saveLoading || loadPdfLoading}
                      icon='print'
                    />
                    <PhotoUploader
                      blockStyle='flex gap-2'
                      desktopButtonText='Choose file'
                      desktopButtonStyle='identification-image-upload-button !text-base lg:!text-lg !w-[136px] lg:!w-[150px]'
                      showQRCodeButton
                      qrcodeButtonText='From mobile'
                      qrcodeButtonStyle='identification-image-upload-button !text-base lg:!text-lg !w-[136px] lg:!w-[150px]'
                      callback={(image) => onSignedImagesUploaded(image)}
                      maxFiles={1}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className='flex justify-between items-center mb-5 mt-12 md:mt-12 relative'>
          <DefaultButton
            title={`← ${t('Go Back')}`}
            onClick={onBack}
            classname='secondary_button min-w-[200px]'
            disabled={saveLoading || loadPdfLoading || uploadSignatureLoading}
          />

          <DefaultButton
            title={`${t('Complete Appointment')} →`}
            onClick={onSave}
            classname='main_button min-w-[200px]'
            loading={saveLoading}
            disabled={
              saveLoading ||
              loadPdfLoading ||
              uploadSignatureLoading ||
              !agreements.return_form ||
              (shouldCheckClient && formErrors.length > 0)
            }
          />
        </div>
      </div>

      <SignatureModal
        showed={showSignatureModal}
        closeModal={() => setShowSignatureModal(false)}
        agreement={selectedTab as IUploadSignatureData['agreement']}
        isConsignedReturn
      />
      <LoadSignatureViaMobileQrCode
        visible={showQrCode}
        setVisible={setShowQrCode}
        qrCodeValue={`${window.location.origin}/${i18n.language}/add-signature/?signature_type=${selectedTab as IUploadSignatureData['agreement']}&amount=${signatureAmount}&upload_token=${token}&user_token=${sessionStorage.getItem(LOCAL_ACCESS_TOKEN)}&isConsignedReturn=true&items=${encodeURIComponent(JSON.stringify(returnItems.map((item) => item.id || 0)))}&sessionToken=${sessionToken}`}
        token={token}
        signature_type={selectedTab as IUploadSignatureData['agreement']}
      />

      <MessageModal
        opened={openErrorModal}
        title='Error'
        text={t('Please fill in all required fields')}
        onClose={() => setOpenErrorModal(false)}
        type='error'
        errors={formErrors}
      />
    </>
  )
}
