import React, { useEffect, useRef, useState } from 'react'
import { Modal } from '../../modal'
import { IconClose } from '../../../images/IconClose'
import { useTranslation } from 'react-i18next'
import { DefaultButton } from '../../defaultButton'
import { IEmailSendData } from '../../../hooks/useOfferEmails'
import { Loader } from '../../loader'
import JoditEditor from 'jodit-react'
import { IconAddPerson } from '../../../images/IconAddPerson'
import { useSelector } from 'react-redux'
import { IStore } from '../../../types/storeTypes'
import { DefaultTooltip } from '../../defaultTooltip'
import { IconEdit } from '../../../images/IconEdit'
import { IconDelete } from '../../../images/IconDelete'
import { emailIsValid } from '../../../utils/validateFilelds'
import { useConsignedConvert } from '../../../hooks/useConsignedConvert'

interface Props {
  showed: boolean
  closeModal: () => void
  offerValidDate?: string
}

const config = {
  readonly: false,
  placeholder: 'Start typings...',
  statusbar: false,
  zIndex: 100,
  height: '250px',
  editorCssClass: 'editorStyle',
  buttons: [
    // 'source',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    'font',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    'image',
    'table',
    'link',
    '|',
    'align',
    'hr',
    'eraser',
    '|',
    'fullsize',
    '|',
    'undo',
    'redo',
  ],
  buttonsXS: ['bold', 'image', '|', 'brush', 'paragraph', '|', 'align', '|', 'undo', 'redo', '|', 'eraser', 'dots'],
  addNewLine: false,
}

export const SignatureEmailModal: React.FC<Props> = ({ showed, closeModal, offerValidDate }) => {
  const { t } = useTranslation()
  const { loadConsignedConvertEmailData, loadEmailLoading, sendConsignedConvertEmail, sendEmailLoading } =
    useConsignedConvert()
  const client = useSelector((store: IStore) => store.client)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const editor = useRef(null)
  const [emailData, setEmailData] = useState<IEmailSendData>({
    body: '',
    to: '',
    subject: '',
    inputCopy: '',
    copy: [],
    attachments: [],
  })
  const [showCopy, setShowCopy] = useState(false)
  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    if (showed) {
      loadConsignedConvertEmailData((data) => setEmailData({ inputCopy: '', attachments: [], ...data, copy: [] }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showed])

  const formClear = () => {
    setShowCopy(false)
    setEmailData({
      body: '',
      to: '',
      subject: '',
      inputCopy: '',
      copy: [],
      attachments: [],
    })
  }

  const onClose = () => {
    formClear()
    setErrors([])
    closeModal()
  }

  const onCancel = () => {
    onClose()
  }

  const onSave = async () => {
    const newErrors: string[] = []
    if (!emailIsValid(emailData.to)) {
      newErrors.push('email_to')
    }
    if (emailData.inputCopy && !emailIsValid(emailData.inputCopy)) {
      newErrors.push('copy_error')
    }

    if (newErrors.length > 0) {
      return setErrors([...newErrors])
    }

    const copyArr = emailData.copy
      ? emailData.inputCopy
        ? [...emailData.copy, emailData.inputCopy]
        : emailData.copy
      : undefined
    const data: IEmailSendData = {
      to: emailData.to,
      subject: emailData.subject,
      body: emailData.body,
      copy: copyArr,
      attachments: [],
    }
    await sendConsignedConvertEmail(data, () => {
      onClose()
    })
  }

  return (
    <Modal showed={showed} bodyStyle='w-[1080px] !max-w-[95vw] xl:!max-w-[80vw] relative'>
      {loadEmailLoading ? (
        <div className='w-full h-[40vh] flex justify-center items-center'>
          <Loader className='h-[100px]' />
        </div>
      ) : (
        <div className=' max-h-[80vh] overflow-y-auto pr-2 mt-3 custom_scroll'>
          <div className='absolute top-6 right-10'>
            <button type='button' onClick={onClose}>
              <IconClose />
            </button>
          </div>

          <div className='flex flex-col gap-7'>
            <div className='w-full flex items-center pr-2 md:pr-12 text-zinc-800 text-[28px] leading-9 font-medium'>
              {t('New mail for {{name}} Offer B{{offer_id}}', {
                name: client.full_name || appointmentInfo.intermediate_save?.client_name,
                offer_id: appointmentInfo.intermediate_save?.offerId,
              })}
            </div>

            <div className='w-full flex items-center relative'>
              <label
                htmlFor='input-email-to'
                className={`w-1/4 flex form_label ${errors.includes('email_to') ? 'error_label' : ''}`}
              >
                {t('To')}
              </label>
              <input
                type='email'
                name='email-to'
                id='input-email-to'
                value={emailData.to}
                onChange={(e) => {
                  setEmailData({ ...emailData, to: e.target.value })
                  setErrors(errors.filter((error) => error !== 'email_to'))
                }}
                className={`form-input w-3/4 h-[42px] !pr-10 ${errors.includes('email_to') ? 'error_field' : ''}`}
                autoComplete='false'
              />

              <button type='button' className='absolute right-3 group' onClick={() => setShowCopy(true)}>
                <IconAddPerson classname='text-zinc-500 group-hover:text-zinc-700' />
              </button>

              {errors.includes('email_to') && (
                <div className=' absolute text-red-500 bottom-[-22px] pl-1 right-0 text-sm w-3/4 '>
                  {t('Please enter a valid email address')}
                </div>
              )}
            </div>

            {showCopy && (
              <>
                {emailData.copy && emailData.copy.length > 0 ? (
                  <div className='flex w-full justify-end mb-[-12px]'>
                    <div className='w-3/4 flex gap-2 flex-wrap'>
                      {emailData.copy?.map((email, index) => {
                        return (
                          <div
                            key={`copy_email-${index}`}
                            className='p-2 bg-slate-200 text-black rounded text-sm flex justify-center items-center'
                          >
                            {email}
                            <button
                              type='button'
                              className='group ml-2 disabled:opacity-50 disabled:pointer-events-none'
                              onClick={() => {
                                const oldEmails = emailData.copy ? emailData.copy : []
                                const newEmail = email
                                setEmailData({
                                  ...emailData,
                                  copy: oldEmails.filter((item, itemIndex) => itemIndex !== index),
                                  inputCopy: newEmail,
                                })
                              }}
                            >
                              <IconEdit classname='text-zinc-500 group-hover:text-zinc-700 !w-[14px] !h-[14px]' />
                            </button>
                            <button
                              type='button'
                              className='group ml-2 disabled:opacity-50 disabled:pointer-events-none'
                              onClick={() => {
                                const oldEmails = emailData.copy ? emailData.copy : []
                                setEmailData({
                                  ...emailData,
                                  copy: oldEmails.filter((item, itemIndex) => itemIndex !== index),
                                })
                              }}
                            >
                              <IconDelete classname='text-zinc-500 group-hover:text-zinc-700 !w-[14px] !h-[14px]' />
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ) : null}
                <div className='w-full flex items-center relative'>
                  <label
                    htmlFor='input-email-copy'
                    className={`w-1/4 flex form_label ${errors.includes('copy_error') ? 'error_label' : ''}`}
                  >
                    {t('Copy')}
                    <DefaultTooltip id='plaid-help' clickable place='right'>
                      {t('You can add app to 5 email addresses')}
                    </DefaultTooltip>
                  </label>
                  <input
                    type='email'
                    name='email-copy'
                    id='input-email-copy'
                    value={emailData.inputCopy || ''}
                    onChange={(e) => {
                      if (e.target.value && [','].includes(e.target.value[e.target.value.length - 1])) {
                        if (!emailIsValid(e.target.value)) {
                          return setErrors([...errors, 'copy_error'])
                        }
                        const oldEmails = emailData.copy ? emailData.copy : []
                        const newEmail = e.target.value ? e.target.value.slice(0, e.target.value.length - 1) : ''
                        setEmailData({ ...emailData, copy: [...oldEmails, newEmail], inputCopy: '' })
                      } else {
                        if (e.target.value[0] === ' ') {
                          setEmailData({ ...emailData, inputCopy: '' })
                        } else {
                          setErrors(errors.filter((error) => error !== 'copy_error'))
                          setEmailData({ ...emailData, inputCopy: e.target.value })
                        }
                      }
                    }}
                    className={`form-input w-3/4 h-[42px] ${errors.includes('copy_error') ? 'error_field' : ''}`}
                    autoComplete='false'
                    formNoValidate
                    onKeyUp={(e) => {
                      const oldEmails = emailData.copy ? emailData.copy : []
                      const newEmail = emailData.inputCopy || ''
                      if ((e.key === 'Enter' || e.keyCode === 13) && newEmail.length > 1) {
                        if (!emailIsValid(newEmail)) {
                          return setErrors([...errors, 'copy_error'])
                        }
                        setEmailData({ ...emailData, copy: [...oldEmails, newEmail], inputCopy: '' })
                      }
                      if ((e.key === ' ' || e.key === 'Spacebar') && newEmail.length > 1) {
                        if (!emailIsValid(newEmail)) {
                          return setErrors([...errors, 'copy_error'])
                        }
                        setEmailData({ ...emailData, copy: [...oldEmails, newEmail], inputCopy: '' })
                      }
                    }}
                    disabled={emailData.copy && emailData.copy.length >= 5}
                  />

                  {errors.includes('copy_error') && (
                    <div className=' absolute text-red-500 bottom-[-22px] pl-1 right-0 text-sm w-3/4 '>
                      {t('Please enter a valid email address')}
                    </div>
                  )}
                </div>
              </>
            )}

            <div className='w-full flex items-center'>
              <label htmlFor='input-email-subject' className='w-1/4 flex form_label'>
                {t('Subject')}
              </label>
              <input
                type='email'
                name='email-subject'
                id='input-email-subject'
                value={emailData.subject}
                onChange={(e) => setEmailData({ ...emailData, subject: e.target.value })}
                className='form-input w-3/4 h-[42px]'
                autoComplete='false'
              />
            </div>

            <div className=''>
              <JoditEditor
                ref={editor}
                value={emailData.body}
                config={config}
                onBlur={(newContent) => setEmailData({ ...emailData, body: newContent })}
              />
            </div>

            <div className='flex flex-col justify-end md:flex-row gap-4 md:gap-8 w-full md:w-auto'>
              <DefaultButton
                title={t('Cancel')}
                classname='text_button !text-red-400 w-full md:w-[200px]'
                disabled={sendEmailLoading}
                onClick={onCancel}
              />
              <DefaultButton
                title={t('Send')}
                classname='main_button w-full md:w-[200px]'
                disabled={sendEmailLoading || !emailData.to || !emailData.body || errors.length > 0}
                onClick={onSave}
                loading={sendEmailLoading}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}
