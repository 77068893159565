import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['sessionData'] = {
  sessionId: null,
}

export const sessionSlice = createSlice({
  name: 'sessionData',
  initialState,
  reducers: {
    setSessionID: (state, action) => {
      return {
        ...state,
        sessionId: action.payload,
      }
    },
  },
})

export const { setSessionID } = sessionSlice.actions

export default sessionSlice.reducer
