import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from '../../modal'
import { IconClose } from '../../../images/IconClose'
import { useTranslation } from 'react-i18next'
import { IOfferItem } from '../../../types/offerTypes'
import { useSelector } from 'react-redux'
import { IStore } from '../../../types/storeTypes'
import { Loader } from '../../loader'
import { DefaultButton } from '../../defaultButton'
import { getCurrency } from '../../../utils/getCurrency'
import { useOfferItem } from '../../../hooks/useOfferItem'
// import { ShowMoreBlock } from '../showMoreBlock'
import { MultiSelect } from '../../multiSelect'
import { getCurrencySymbol } from '../../../utils/getCurrencySymbol'
import { ACQUIRED, CONSIGNMENT, DECLINED, FOLLOWUP, INELIGIBLE } from '../../../utils/itemStatuses'

interface Props {
  showed: boolean
  closeModal: () => void
  onItemsSave: (items: IOfferItem[]) => void
  startItem: IOfferItem
}

export const AddItemStatusModal: React.FC<Props> = ({ showed, closeModal, onItemsSave, startItem }) => {
  const { t, i18n } = useTranslation()
  const [items, setItems] = useState<IOfferItem[]>([])
  const [forAllItems, setForAllItems] = useState(false)
  const [hideForAllItems, setHideForAllItems] = useState(false)
  const [currentItem, setCurrentItem] = useState<IOfferItem | null>(null)
  const [swapItemLoading, setSwapItemLoading] = useState(false)
  const offerDicts = useSelector((store: IStore) => store.offerDicts)
  const offerInfo = useSelector((store: IStore) => store.offerInfo)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const { offerItems } = offerInfo
  const { getOfferItemTitle } = useOfferItem()
  const { offerDictsData } = offerDicts

  const currentItemIndex = useMemo(() => {
    if (!currentItem) return 0
    else return items.findIndex((item) => currentItem.id === item.id)
  }, [currentItem, items])

  useEffect(() => {
    setItems(offerItems)
  }, [offerItems, showed])

  useEffect(() => {
    if (showed) setCurrentItem(startItem)
  }, [startItem, showed])

  useEffect(() => {
    if (currentItem?.status) {
      if (currentItem.status === INELIGIBLE) setCurrentItem({ ...currentItem, offer_made: currentItem.offer_made })
      if (currentItem.status === DECLINED) setCurrentItem({ ...currentItem, offer_made: currentItem.offer_made })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem?.id, currentItem?.status, showed])

  const editField = (name: keyof IOfferItem, value: any) => {
    if (currentItem) {
      if (name === 'status') {
        setCurrentItem({
          ...currentItem,
          [name]: value,
          client_expectation: undefined,
          target_amount: value === CONSIGNMENT ? currentItem.price : undefined,
          reason_decline: undefined,
          offer_made: currentItem.offer_made,
        })
      } else {
        setCurrentItem({ ...currentItem, [name]: value })
      }
    }
  }

  const onClose = () => {
    onItemsSave(items)
    setCurrentItem(null)
    setForAllItems(false)
    closeModal()
  }

  const onSave = (goNext?: boolean) => {
    if (currentItem) {
      const newItems = items.map((item, index) => {
        if (index < currentItemIndex) return item
        else if (item.id === currentItem.id) {
          return {
            ...item,
            status: currentItem.status,
            client_expectation: currentItem.client_expectation,
            target_amount: currentItem.target_amount ? +currentItem.target_amount : currentItem.target_amount,
            reason_decline: currentItem.reason_decline,
            offer_made: currentItem.offer_made,
          }
        } else if (forAllItems && currentItem.status !== CONSIGNMENT) {
          return {
            ...item,
            status: currentItem.status,
            client_expectation: undefined,
            target_amount: undefined,
            reason_decline: undefined,
            offer_made: item.offer_made,
          }
        } else return item
      })
      setItems(newItems)
      if (forAllItems) setHideForAllItems(true)
      if (currentItem.status && currentItem.status === ACQUIRED && !goNext) {
        onItemsSave(newItems)
        setCurrentItem(null)
        setForAllItems(false)
        closeModal()
      } else if (
        currentItem.status &&
        currentItem.status === CONSIGNMENT &&
        forAllItems &&
        currentItemIndex + 1 !== newItems.length
      ) {
        const nextItem = newItems[currentItemIndex + 1]
        setSwapItemLoading(true)
        setTimeout(() => {
          setCurrentItem({
            ...nextItem,
            status: CONSIGNMENT,
            client_expectation: undefined,
            target_amount: nextItem.price,
            reason_decline: undefined,
            offer_made: nextItem.offer_made,
          })
          setSwapItemLoading(false)
        }, 300)
      } else {
        if (!goNext) {
          onItemsSave(newItems)
          setCurrentItem(null)
          setForAllItems(false)
          closeModal()
        } else {
          setSwapItemLoading(true)
          setTimeout(() => {
            setCurrentItem(newItems[currentItemIndex + 1])
            setSwapItemLoading(false)
          }, 300)
        }
      }
    }
  }

  const statusesList = useMemo(
    () =>
      offerDictsData?.item_statuses
        ? Object.entries(offerDictsData.item_statuses).map((status) => {
            return { label: t(status[1]), id: status[0], value: status[0] }
          })
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offerDictsData, i18n.language]
  )

  const defaultStatus = useMemo(() => {
    return currentItem && currentItem.status
      ? statusesList.find((listItem) => listItem.value === currentItem.status)
      : null
  }, [currentItem, statusesList])

  const declinedReasonsList = useMemo(
    () =>
      offerDictsData?.declined_reasons
        ? Object.entries(offerDictsData.declined_reasons).map((reason) => {
            return { label: t(reason[1]), id: reason[1], value: reason[1] }
          })
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offerDictsData, i18n.language]
  )

  const defaultReasonDecline = useMemo(() => {
    return currentItem && currentItem.reason_decline
      ? declinedReasonsList.find((listItem) => listItem.value === currentItem.reason_decline)
      : null
  }, [currentItem, declinedReasonsList])

  const isHongkong = useMemo(() => {
    return appointmentInfo.intermediate_save &&
      appointmentInfo.intermediate_save.office &&
      appointmentInfo.intermediate_save.office.country &&
      appointmentInfo.intermediate_save.office.country.toLowerCase() === 'hk'
      ? true
      : false
  }, [appointmentInfo.intermediate_save])

  const showOnlySave =
    currentItemIndex + 1 === items.length || (currentItem && currentItem.status === ACQUIRED && forAllItems)

  return (
    <Modal showed={showed} bodyStyle='!w-[600px] !max-w-[95vw] xl:!max-w-[80vw] relative'>
      <div className='absolute top-3 right-5'>
        <button type='button' onClick={onClose}>
          <IconClose />
        </button>
      </div>
      <div className='flex flex-col gap-6 pt-4'>
        {swapItemLoading || !currentItem ? (
          <div className='w-full min-h-[50vh] flex justify-center items-center'>
            <Loader className='h-[100px]' />
          </div>
        ) : (
          <>
            <div className='w-full flex flex-col min-h-[50vh]'>
              <div className='w-full flex items-center pr-2 md:pr-12 text-zinc-800 text-[28px] leading-9 font-medium'>
                {`#${currentItemIndex + 1} ${t('Item')}`}
              </div>

              <div className='mt-2 text-sm text-zinc-500'>
                {currentItem.price && (
                  <div className='font-medium'>
                    {getCurrency(currentItem.price, appointmentInfo.intermediate_save?.currency || 'USD')}
                  </div>
                )}
                {getOfferItemTitle(currentItem)}
              </div>

              <div className='mt-6'>
                <div className=' flex flex-col gap-6'>
                  <div className='w-full flex items-center'>
                    <label htmlFor='input-item-status' className='w-1/3 flex form-offer-item_label'>
                      {t('Status')}
                    </label>
                    <MultiSelect
                      selectId='select-item-status'
                      inputId='input-item-status'
                      options={statusesList}
                      onChange={(value) => editField('status', value)}
                      value={defaultStatus}
                      containerStyles='w-2/3 h-[38px]'
                      controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                      controlDefaultStyles='form-offer-item-select'
                      inputStyles='!text-zinc-900'
                    />
                  </div>

                  {currentItem.status && currentItem.status === CONSIGNMENT && (
                    <div className='w-full flex items-center'>
                      <label htmlFor='input-item-target_amount' className={`w-1/3 flex form-offer-item_label`}>
                        {t('Target amount')}
                        <span className='text-red-500'>*</span>
                      </label>
                      <div className='relative w-2/3'>
                        <label htmlFor='input-item-target_amount' className='absolute top-[4px] left-2'>
                          {getCurrencySymbol(appointmentInfo.intermediate_save?.currency || 'USD')}
                        </label>
                        <input
                          type='text'
                          name='target_amount'
                          id='input-item-target_amount'
                          value={currentItem.target_amount || ''}
                          onChange={(e) => {
                            var regex = /^\d+$/
                            if (e.target.value.match(regex) || e.target.value === '') {
                              editField('target_amount', e.target.value)
                            }
                          }}
                          className={`form-input ${isHongkong ? '!pl-12' : '!pl-6'} w-full h-[38px]`}
                          autoComplete='false'
                        />
                      </div>
                    </div>
                  )}

                  {currentItem.status && [DECLINED, FOLLOWUP, INELIGIBLE].includes(currentItem.status) && (
                    <div className='w-full flex items-center'>
                      <label htmlFor='input-item-client_expectation' className={`w-1/3 flex form-offer-item_label`}>
                        {t('Client Expectation')}
                      </label>
                      <div className='relative w-2/3'>
                        <label htmlFor='input-item-client_expectation' className='absolute top-[4px] left-2'>
                          {getCurrencySymbol(appointmentInfo.intermediate_save?.currency || 'USD')}
                        </label>
                        <input
                          type='text'
                          name='client_expectation'
                          id='input-item-client_expectation'
                          value={currentItem.client_expectation || ''}
                          onChange={(e) => {
                            var regex = /^\d+$/
                            if (e.target.value.match(regex) || e.target.value === '') {
                              editField('client_expectation', e.target.value)
                            }
                          }}
                          className={`form-input ${isHongkong ? '!pl-12' : '!pl-6'} w-full h-[38px]`}
                          autoComplete='false'
                        />
                      </div>
                    </div>
                  )}

                  {currentItem.status && [DECLINED].includes(currentItem.status) && (
                    <div className='w-full flex items-center'>
                      <label htmlFor='input-item-reason_decline' className={`w-1/3 flex form-offer-item_label`}>
                        {t('Reason')}
                      </label>
                      <MultiSelect
                        selectId='select-item-reason_decline'
                        inputId='input-item-reason_decline'
                        options={declinedReasonsList}
                        onChange={(value) => editField('reason_decline', value)}
                        value={defaultReasonDecline}
                        containerStyles='w-2/3 h-[38px]'
                        controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                        controlDefaultStyles='form-offer-item-select'
                        inputStyles='!text-zinc-900'
                      />
                    </div>
                  )}

                  {currentItem.status && [DECLINED, INELIGIBLE].includes(currentItem.status) && (
                    <div className='w-full flex items-center'>
                      <label htmlFor='input-item-offer_made' className={`w-1/3 flex form-offer-item_label`}>
                        {t('Offer made')}
                      </label>
                      <div className='relative w-2/3'>
                        <input
                          type='checkbox'
                          name='offer_made'
                          id='input-item-offer_made'
                          checked={!!currentItem.offer_made}
                          onChange={() => editField('offer_made', !currentItem.offer_made)}
                          className={`form-input w-[24px] h-[24px] accent-green-700`}
                          autoComplete='false'
                        />
                      </div>
                    </div>
                  )}
                </div>

                {currentItem.status && !hideForAllItems && items.length > 1 && (
                  <div className='border-t border-zinc-200 pt-[30px] mt-[30px]'>
                    <div className='w-full flex items-center'>
                      <label
                        htmlFor='input-item-set_fort_all_items'
                        className='w-1/3 flex form-offer-item_label !text-green-700'
                      >
                        {t('Set for remaining items')}
                      </label>
                      <div className='w-2/3 h-[24px] flex justify-between items-center'>
                        <input
                          type='checkbox'
                          name='set_fort_all_items'
                          id='input-item-set_fort_all_items'
                          checked={!!forAllItems}
                          onChange={() => setForAllItems(!forAllItems)}
                          className={`form-input w-[24px] h-[24px] accent-green-700`}
                          autoComplete='false'
                        />

                        <span className='text-sm text-zinc-500'>
                          {t('The {{status}} status will be applied to all the Items', {
                            status: t(currentItem.status[0].toUpperCase() + currentItem.status.slice(1)),
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              className={`flex flex-col gap-4 md:flex-row ${showOnlySave ? 'justify-end' : 'justify-between'}  items-center mt-6 [&>div]:w-full [&>div]:md:w-fit`}
            >
              {showOnlySave ? (
                <DefaultButton
                  title={t('Save')}
                  onClick={() => onSave()}
                  disabled={!currentItem?.status || (currentItem.status === CONSIGNMENT && !currentItem.target_amount)}
                  classname='main_button w-full md:w-[220px]'
                />
              ) : (
                <>
                  <DefaultButton
                    title={t('Save')}
                    onClick={() => onSave()}
                    disabled={
                      !currentItem?.status || (currentItem.status === CONSIGNMENT && !currentItem.target_amount)
                    }
                    classname='add_status_save w-full md:w-[200px]'
                  />

                  <DefaultButton
                    title={`${t('Save & Next Item')} →`}
                    onClick={() => onSave(true)}
                    disabled={
                      !currentItem?.status || (currentItem.status === CONSIGNMENT && !currentItem.target_amount)
                    }
                    classname='main_button w-full md:w-[220px]'
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
