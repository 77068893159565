/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import QRCode from 'qrcode.react'
import { useDispatch, useSelector } from 'react-redux'
import { callApi } from '../../../../utils/callApi'
import { addPhotos } from '../../../../store/photoViaMobile'
import { IStore } from '../../../../types/storeTypes'
import { useTranslation } from 'react-i18next'
import { IconDone } from '../../../../images/IconDone'

interface Props {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  qrCodeValue: string
  token: string
  locale: string
}

export const LoadImagesViaMobileQrCode: React.FC<Props> = ({ visible, setVisible, qrCodeValue, token, locale }) => {
  const dispatch = useDispatch()
  const photoViaMobile = useSelector((store: IStore) => store.photoViaMobile)
  const { t } = useTranslation()

  const [mobileSessionIsActive, setMobileSessionIsActive] = useState<boolean>(false)
  const [mobileSessionWasActive, setMobileSessionWasActive] = useState(false)

  const onClose = () => {
    setMobileSessionIsActive(false)
    setMobileSessionWasActive(false)
    setVisible(false)
  }

  // console.log(qrCodeValue, 'qrCodeValue')

  const onEndSession = () => {}

  const listenMobileSession = () => {
    return setInterval(async () => {
      try {
        const response: any = await callApi({
          method: 'GET',
          url: `/booking/images/flag?token=${token}`,
          v1: true,
        })
        setMobileSessionIsActive((pr) => {
          if (pr !== response.data.flag) {
            if (pr && !response.data.flag) onEndSession()
            return response.data.flag
          }
          return pr
        })
        if (response.data.flag) setMobileSessionWasActive(true)
      } catch (e) {
        console.error(e)
      }
    }, 5000)
  }

  const getImages = async () => {
    try {
      const response: any = await callApi({
        method: 'GET',
        url: `/booking/${locale}/images?token=${token}`,
        v1: true,
      })
      if (Array.isArray(response.data)) {
        dispatch(addPhotos({ token, data: response.data }))
      }
    } catch (e) {
      console.error(e)
    }
  }

  const listenImages = () => setInterval(getImages, 5000)

  useEffect(() => {
    const el = document.getElementById('main_screen_body')
    if (el) visible ? el.classList.remove('animate__fadeIn') : el.classList.add('animate__fadeIn')

    let imagesListener: any = null
    if (visible) {
      const mobileSessionListener = listenMobileSession()
      imagesListener = listenImages()
      return () => {
        clearInterval(mobileSessionListener)
        clearInterval(imagesListener)
      }
    }
  }, [visible])

  useEffect(() => {
    setMobileSessionIsActive(false)
    setMobileSessionWasActive(false)
  }, [qrCodeValue])

  useEffect(() => {
    if (photoViaMobile[token] || (mobileSessionWasActive && !mobileSessionIsActive)) setVisible(false)
  }, [photoViaMobile[token], mobileSessionWasActive, mobileSessionIsActive])

  return visible ? (
    <div className='fixed inset-0 flex justify-center items-center bg-black/20 z-[1000]' style={{ top: -20 }}>
      <div className='bg-white p-4 flex justify-center items-center flex-col gap-1' style={{ width: 640 }}>
        <h4 className='text-2xl font-semibold font-serif'>{t('Use Camera Phone')}</h4>
        <p className='mb-5 text-lg text-zinc-700'>{t('Scan QR code with mobile device camera to begin')}.</p>
        <div className='relative'>
          <QRCode value={qrCodeValue} size={160} className={mobileSessionIsActive ? 'opacity-10' : ''} />
          {mobileSessionIsActive && (
            <div className='absolute inset-0 flex flex-col justify-center items-center gap-1'>
              <IconDone classname='w-12 h-12' />
              <h3 className='text-green-700 text-lg font-semibold'>{t('Authorization')}</h3>
            </div>
          )}
        </div>
        <div className='mt-4'>
          <p className='text-zinc-700'>{t('To cancel and close this page click End Session on your phone')}</p>
        </div>
        <div className='mt-4 mb-2'>
          <button
            type='button'
            onClick={onClose}
            className=' text-red-500 text-base disabled:opacity-35'
            aria-label='Close this window'
          >
            {mobileSessionIsActive ? t('Cancel session') : t('Close this window')}
          </button>
        </div>
      </div>
    </div>
  ) : null
}
