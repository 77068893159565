import { combineReducers, configureStore } from '@reduxjs/toolkit'
import clientInfoSliceReducer from './clientInfoSlice'
import dictsSliceReducer from './dictsSlice'
import appointmentInfoSliceReducer from './appointmentInfoSlice'
import photoViaMobileReducer from './photoViaMobile'
import globalPopupSliceReducer from './globalPopupSlice'
import offerDictsSLiceReducer from './offerDictsSLice'
import offerInfoSliceReducer from './offerInfoSlice'
import currentStepSliceReducer from './currentStepSlice'
import paymentInfoSliceReducer from './paymentInfoSlice'
import agreementsSliceReducer from './agreementsSlice'
import confirmationDataSliceReducer from './confirmationDataSlice'
import paymentInfoFromMobileSliceReducer from './paymentInfoFromMobileSlice'
import closeFormModalSliceReducer from './closeFormModalSlice'
import sessionLoadingErrorSliceReducer from './sessionLoadingErrorSlice'
import showAgreementsWarnSliceReducer from './showAgreementsWarnSlice'
import consignmentConversionSliceReducer from './consignmentConversionSlice'
import consignmentReturnSliceReducer from './consignmentReturnSlice'
import sessionDataReducer from './sessionSlice'

const rootReducer = combineReducers({
  client: clientInfoSliceReducer,
  dicts: dictsSliceReducer,
  appointmentInfo: appointmentInfoSliceReducer,
  photoViaMobile: photoViaMobileReducer,
  globalPopup: globalPopupSliceReducer,
  offerDicts: offerDictsSLiceReducer,
  offerInfo: offerInfoSliceReducer,
  currentStep: currentStepSliceReducer,
  paymentInfo: paymentInfoSliceReducer,
  agreements: agreementsSliceReducer,
  confirmationData: confirmationDataSliceReducer,
  paymentInfoFromMobile: paymentInfoFromMobileSliceReducer,
  closeFormModal: closeFormModalSliceReducer,
  sessionLoadingError: sessionLoadingErrorSliceReducer,
  showAgreementsWarn: showAgreementsWarnSliceReducer,
  consignmentConversion: consignmentConversionSliceReducer,
  consignmentReturn: consignmentReturnSliceReducer,
  sessionData: sessionDataReducer,
})

export const store = configureStore({
  reducer: rootReducer,
})
