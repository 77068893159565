import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['agreements'] = {
  agreements_uploaded: false,
  consignment_agreement: null,
  offer_form: null,
  return_form: null,
  purchase_form: null,
}

export const agreementsSlice = createSlice({
  name: 'agreements',
  initialState,
  reducers: {
    setAgreementsInfo: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setAgreementsSignatureImages: (state, action) => {
      return {
        ...state,
        signature_images: action.payload,
      }
    },
    setAgreementsConsignment: (state, action) => {
      return {
        ...state,
        consignment_agreement: action.payload,
      }
    },
    setAgreementsOffer: (state, action) => {
      return {
        ...state,
        offer_form: action.payload,
      }
    },
    setAgreementsPurchase: (state, action) => {
      return {
        ...state,
        purchase_form: action.payload,
      }
    },
    setAgreementsReturn: (state, action) => {
      return {
        ...state,
        return_form: action.payload,
      }
    },
  },
})

export const {
  setAgreementsInfo,
  setAgreementsSignatureImages,
  setAgreementsConsignment,
  setAgreementsOffer,
  setAgreementsPurchase,
  setAgreementsReturn,
} = agreementsSlice.actions

export default agreementsSlice.reducer
