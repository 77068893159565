export const ApiRoutes = {
  formStart: '/start',
  firsStepSave: '/client',
  lockForm: '/lock',
  unlockForm: '/unlock',
  saveForm: '/save-tmp',
  secondStep: '/offer',
  checkReport: '/cert-info',
  thirdStep: '/decision',
  internalNote: '/internal-note',
  print: '/print',
  printItems: '/print-items',
  fourthStep: '/payment',
  agreements: '/agreements',
  upload: '/upload',
  formFinish: '/finish',
  sixthStep: '/confirmation',
  email: '/email',
  paymentQR: '/payment/qr',
  plaidData: '/plaid/data',
  plaidToken: '/plaid/access-token',
  plaidLinkToken: '/plaid/link-token',
  intermediateSave: '/intermediate-save',
  printContract: '/contract',
  gdpr: '/gdpr',

  // related clients
  relatedClient: '/related-client',
  relatedClientSearch: '/related-client/search',
  relatedClientGdpr: '/related-client/gdpr',
  relatedClientBind: '/related-client/bind',

  // consigned convert
  convert: '/convert',
  conversionEmail: '/conversion-link-email',

  // consigned return
  process: '/process',
}
