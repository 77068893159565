import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from '../locales/en.json'
import es from '../locales/es.json'
import ca from '../locales/ca.json'

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  ca: {
    translation: ca,
  },
}

const languagesList = ['en', 'es', 'ca']

const options = {
  // order and from where user language should be detected
  order: ['path', 'localStorage', 'querystring', 'cookie'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)

  .init({
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    supportedLngs: languagesList,
    fallbackLng: languagesList,
    detection: options,
  })

export default i18n
