import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '../../../../types/storeTypes'
import { IconNoData } from '../../../../images/IconNoData'
import { DefaultButton } from '../../../defaultButton'
import { Loader } from '../../../loader'
import { useFormSession } from '../../../../hooks/useFormSession'
import { IOfferItem } from '../../../../types/offerTypes'
import { IconSettings } from '../../../../images/IconSettings'
import { IconEdit } from '../../../../images/IconEdit'
import { IconDelete } from '../../../../images/IconDelete'
import { getCurrency } from '../../../../utils/getCurrency'
import { useOfferItem } from '../../../../hooks/useOfferItem'
import { EditItemModal } from '../../../modals/editItemModal'
import { getCurrencySymbol } from '../../../../utils/getCurrencySymbol'
import { IconDoneSimple } from '../../../../images/IconDoneSimple'
import { MessageModal } from '../../../modals/messageModal'
import { Tooltip } from 'react-tooltip'
import { IconUndo } from '../../../../images/IconUndo'
import { v4 as uuidv4 } from 'uuid'
import { IconPlus } from '../../../../images/IconPlus'
import { AddConsignedReturnItemsModal } from '../../../modals/addConsignedReturnItemsModal'
import { useGetConsignedReturnStepData } from '../../../../hooks/useGetConsignedReturnStepData'
import { setConsignmentReturnItems } from '../../../../store/consignmentReturnSlice'
import { showGlobalPopup } from '../../../../store/globalPopupSlice'
import { ApiRoutes } from '../../../../utils/apiRoutes'
import { callApi } from '../../../../utils/callApi'

const requiredFields = ['price', 'additional_description', 'subproduct']

interface IItemsToReturn {
  [key: string]: number
}

interface Props {}

export const SecondStep: React.FC<Props> = () => {
  const { t } = useTranslation()
  const consignmentReturn = useSelector((store: IStore) => store.consignmentReturn)
  const { returnItems } = consignmentReturn
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const [saveLoading, setSaveLoading] = useState(false)
  const { stepsLoading, changeCurrentStep, getSignatureStepAgreements } = useGetConsignedReturnStepData()
  const dispatch = useDispatch()
  const { saveSession } = useFormSession()
  const [showAddItemModal, setShowAddItemModal] = useState(false)
  const [itemEdit, setItemEdit] = useState<{ item: IOfferItem; index: number } | null>(null)
  const { getOfferItemTitle } = useOfferItem()
  const [totalAmount, setTotalAmount] = useState(0)
  const [editTotalAmount, setEditTotalAmount] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [hidePrice, setHidePrice] = useState(false)
  const [hideTotal, setHideTotal] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const currentStep = useSelector((store: IStore) => store.currentStep)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const startValue = sessionStorage.getItem('2_step_startValue')

  const tooltipStyle = 'max-w-[350px] z-10 text-sm font-normal'
  const tooltipDelay = 400

  const onSave = async () => {
    setSaveLoading(true)

    try {
      const itemsToReturn: IItemsToReturn = {}
      returnItems.forEach((item) => {
        if (!!item.id) {
          itemsToReturn[item.id] = item.price || 0
        }
      })
      const data = {
        items: itemsToReturn,
      }
      const resp = await callApi({
        method: 'PUT',
        url: `${ApiRoutes.secondStep}`,
        data,
        isConsignedReturn: true,
      })
      if (resp && resp.data) {
        sessionStorage.removeItem('2_step_items')
        sessionStorage.removeItem('2_step_startValue')
        const { intermediate_save } = appointmentInfo
        const consignmentReturnData =
          !!intermediate_save && !!intermediate_save?.consignmentReturn ? intermediate_save?.consignmentReturn : {}
        const saveData = {
          ...intermediate_save,
          offerId: consignmentReturn.id,
          consignmentReturn: {
            ...consignmentReturnData,
            currentStep: currentStep + 1,
            returnItems: returnItems,
          },
        }
        await saveSession(saveData)
        getSignatureStepAgreements()
        changeCurrentStep(currentStep + 1)
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setSaveLoading(false)
    }
  }

  const onBack = () => {
    changeCurrentStep(currentStep - 1)
  }

  useEffect(() => {
    const step = appointmentInfo.intermediate_save?.currentStep ? appointmentInfo.intermediate_save.currentStep : 0
    if (!returnItems.length && step <= 2) {
      const fromStorage = sessionStorage.getItem('2_step_items')
      const items =
        appointmentInfo.intermediate_save && appointmentInfo.intermediate_save.offerItems
          ? appointmentInfo.intermediate_save?.offerItems
          : fromStorage
            ? (JSON.parse(fromStorage) as IOfferItem[])
            : []
      if (!!items.length) {
        dispatch(setConsignmentReturnItems(items))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentInfo.intermediate_save])

  useEffect(() => {
    if (!!appointmentInfo.intermediate_save?.hide_price_items) {
      setHidePrice(appointmentInfo.intermediate_save?.hide_price_items)
    }

    if (!!appointmentInfo.intermediate_save?.hide_total_price) {
      setHideTotal(appointmentInfo.intermediate_save?.hide_total_price)
    }
  }, [appointmentInfo.intermediate_save])

  useEffect(() => {
    if (returnItems.length) {
      sessionStorage.setItem('2_step_items', JSON.stringify(returnItems))
      const amount = returnItems
        .filter((item) => item.offer_made)
        .reduce((a, b) => {
          return a + (b.price ? +b.price : 0)
        }, 0)
      setTotalAmount(amount)
    } else {
      sessionStorage.removeItem('2_step_items')
      sessionStorage.removeItem('2_step_startValue')
      setTotalAmount(0)
    }
  }, [returnItems])

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Element)) {
      setShowSettings(false)
    }
  }, [])

  useEffect(() => {
    if (showSettings) {
      window.addEventListener('mousedown', handleClickOutside)
    } else {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showSettings, handleClickOutside])

  const onItemDelete = (index: number) => {
    sessionStorage.removeItem('2_step_startValue')
    const newArr = [...returnItems]
    if (newArr.length > 1) {
      newArr.splice(index, 1)
      dispatch(setConsignmentReturnItems(newArr))
    } else {
      dispatch(setConsignmentReturnItems([]))
    }
  }

  const onItemEditSave = (item: IOfferItem, index: number) => {
    sessionStorage.removeItem('2_step_startValue')
    const newArr = [...returnItems]
    newArr[index] = item
    dispatch(setConsignmentReturnItems(newArr))
  }

  const itemsForSelect = useMemo(() => {
    return consignmentReturn.items
      ? consignmentReturn.items.filter((item) => !returnItems.some((returnItem) => returnItem.id === item.id))
      : []
  }, [consignmentReturn.items, returnItems])

  const onTotalAmountSave = (newValue?: number) => {
    const newTotalAmount = newValue ? newValue : totalAmount

    const oldAmount = returnItems
      .filter((item) => item.offer_made)
      .reduce((a, b) => {
        return a + (b.price ? +b.price : 0)
      }, 0)

    if (!startValue && newTotalAmount !== oldAmount) {
      sessionStorage.setItem('2_step_startValue', JSON.stringify(returnItems))
    }

    if (oldAmount < newTotalAmount) {
      const itemsCount = returnItems.filter((item) => item.price && item.price > 0 && item.offer_made).length
      const diff = newTotalAmount - oldAmount
      const division = diff % itemsCount
      const amount = Math.floor(diff / itemsCount)

      const newArr = returnItems.map((item, index) => {
        const oldPrice = item.price ? +item.price : 0
        const isLast = returnItems
          .slice(index + 1)
          .some((item2) => !!item2.price && +item2.price > 0 && item2.offer_made)
        return {
          ...item,
          price: oldPrice > 0 && item.offer_made ? oldPrice + amount + (!isLast ? division : 0) : item.price,
        }
      })
      dispatch(setConsignmentReturnItems(newArr))
    }
    if (oldAmount > newTotalAmount) {
      const minAmount = 10
      let diff = oldAmount - newTotalAmount
      let itemsCount = returnItems.filter((item) => item.price && item.price > 0 && item.offer_made).length
      let division = diff % itemsCount
      let amount = Math.floor(diff / itemsCount)

      const filteredItems = returnItems.filter(
        (item) => item.price && item.offer_made && item.price > 0 && item.price > minAmount
      ).length
      if (filteredItems < itemsCount) {
        itemsCount = filteredItems
        division = diff % filteredItems
        amount = Math.floor(diff / filteredItems)
      }

      if (newTotalAmount < minAmount * itemsCount) return

      const newArr = returnItems.map((item) => {
        return { ...item, unicId: uuidv4() }
      })
      const sortedItems = newArr
        .filter((item) => item.price && item.offer_made && item.price > 0 && item.price > minAmount)
        .sort((a, b) => (a.price || 0) - (b.price || 0))

      sortedItems.forEach((item, index) => {
        const oldPrice = item.price ? +item.price : 0
        const newItemIndex = newArr.findIndex((newArrItem) => newArrItem.unicId === item.unicId)
        const isLast = sortedItems.length - 1 === index
        if (oldPrice > 0 && oldPrice - amount > minAmount && item.offer_made) {
          if (newItemIndex >= 0) {
            newArr[newItemIndex] = {
              ...newArr[newItemIndex],
              price: oldPrice - amount - (!isLast ? division : 0),
            }
            const itemDiff = amount + (!isLast ? division : 0)
            const newDiff = diff - itemDiff
            const newItemsLength = itemsCount - 1
            diff = newDiff
            itemsCount = newItemsLength
            division = newDiff % newItemsLength
            amount = Math.floor(newDiff / newItemsLength)
          }
        } else if (oldPrice > 0 && oldPrice > minAmount && item.offer_made) {
          newArr[newItemIndex] = {
            ...newArr[newItemIndex],
            price: isLast ? oldPrice - amount : minAmount,
          }
          const itemDiff = oldPrice - minAmount
          const newDiff = diff - itemDiff
          const newItemsLength = itemsCount - 1
          diff = newDiff
          itemsCount = newItemsLength
          division = newDiff % newItemsLength
          amount = Math.floor(newDiff / newItemsLength)
        } else {
          newArr[newItemIndex] = {
            ...newArr[newItemIndex],
            price: item.price,
          }
        }
      })
      dispatch(
        setConsignmentReturnItems(
          newArr.map((item) => {
            return { ...item, unicId: undefined }
          })
        )
      )
    }

    setEditTotalAmount(false)
  }

  const onTotalAmountUndo = () => {
    if (startValue) {
      const oldItems = JSON.parse(startValue) as IOfferItem[]
      const newItems = returnItems.map((item, index) => {
        return {
          ...item,
          price: oldItems[index] && oldItems[index].price ? oldItems[index].price : item.price,
        }
      })
      dispatch(setConsignmentReturnItems(newItems))
      sessionStorage.removeItem('2_step_startValue')
    }
  }

  const isHongkong = useMemo(() => {
    return appointmentInfo.intermediate_save &&
      appointmentInfo.intermediate_save.office &&
      appointmentInfo.intermediate_save.office.country &&
      appointmentInfo.intermediate_save.office.country.toLowerCase() === 'hk'
      ? true
      : false
  }, [appointmentInfo.intermediate_save])

  return stepsLoading.includes(currentStep) ? (
    <div className='w-full h-[70vh] flex justify-center items-center'>
      <Loader className='h-[100px]' />
    </div>
  ) : (
    <>
      <div className='flex flex-col'>
        {!!returnItems.length ? (
          <div className=''>
            <div className='text-base font-medium text-zinc-500 mb-2 flex justify-between px-3'>
              <div className=''>
                <span className='mr-3'>#</span>
                {t('Item Descriptions')}
              </div>

              <div className='flex'>
                <div className=' text-end'>{t('Price')}</div>
                <div className='flex justify-end items-center w-[100px] relative'>
                  <button
                    type='button'
                    className='group'
                    onClick={() => setShowSettings(true)}
                    data-tooltip-id='button-edit_price_visibility'
                  >
                    <IconSettings classname='text-zinc-500 group-hover:text-zinc-700' />
                  </button>
                  <Tooltip id='button-edit_price_visibility' className={tooltipStyle} delayShow={tooltipDelay}>
                    {t('Edit visibility')}
                  </Tooltip>

                  {showSettings && (
                    <div
                      className='absolute flex flex-col top-[32px] right-[-12px] bg-white rounded border border-zinc-300 w-[200px]'
                      ref={dropdownRef}
                    >
                      <div className='w-full flex items-center gap-2 px-3 py-2'>
                        <input
                          type='checkbox'
                          name='hide_price'
                          id='input-hide_price'
                          checked={hidePrice}
                          onChange={() => setHidePrice(!hidePrice)}
                          className={`form-input w-[16px] h-[16px] accent-green-700`}
                          autoComplete='false'
                        />
                        <label htmlFor='input-hide_price' className='flex text-base text-zinc-800'>
                          {t('Hide item(s) price')}
                        </label>
                      </div>
                      <div className='w-full flex items-center gap-2 p-3 py-2'>
                        <input
                          type='checkbox'
                          name='hide_total'
                          id='input-hide_total'
                          checked={hideTotal}
                          onChange={() => setHideTotal(!hideTotal)}
                          className={`form-input w-[16px] h-[16px] accent-green-700`}
                          autoComplete='false'
                        />
                        <label htmlFor='input-hide_total' className='flex text-base text-zinc-800'>
                          {t('Hide total price')}
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className=' rounded-lg bg-white flex flex-col divide-y divide-zinc-200 max-h-[50vh] overflow-y-auto custom_scroll'>
              {returnItems.map((item, index) => {
                return (
                  <div
                    key={`${item.type}-${index}`}
                    className=' text-zinc-700 flex justify-between items-center px-3 min-h-[68px] h-[68px]'
                  >
                    <div className='flex items-center'>
                      <div className='text-[22px] pb-1'>{index + 1}</div>
                      <div className=' text-base font-medium px-3 line-clamp-2'>{getOfferItemTitle(item)}</div>
                    </div>
                    <div className='flex'>
                      <div className='text-lg font-medium flex items-center justify-end min-w-[60px]'>
                        {item.price && item.offer_made && !hidePrice
                          ? getCurrency(item.price, appointmentInfo.intermediate_save?.currency)
                          : ''}
                      </div>
                      <div className='w-[100px] flex justify-end pl-3 h-full'>
                        <button
                          type='button'
                          className='px-2 py-2 group'
                          onClick={() => onItemDelete(index)}
                          data-tooltip-id={`button-delete_item-${index}`}
                        >
                          <IconDelete classname='text-zinc-500 group-hover:text-zinc-700' />
                        </button>
                        <Tooltip id={`button-delete_item-${index}`} className={tooltipStyle} delayShow={tooltipDelay}>
                          {t('Delete item')}
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <div className='w-full bg-white rounded-lg h-[50vh] overflow-y-auto custom_scroll flex justify-center items-center'>
            <div className='flex flex-col justify-center items-center'>
              <div className='text-[22px] text-zinc-800'>{t('Please add items')}</div>
              <IconNoData />
              <div className='text-lg text-zinc-400'>{t('No data')}</div>
            </div>
          </div>
        )}

        <div className='flex justify-between flex-col-reverse md:flex-row mt-6  mb-12'>
          <div className='flex justify-between flex-col desktop:flex-row gap-4'>
            {itemsForSelect.length > 0 ? (
              <button
                type='button'
                className='text-white text-base font-medium bg-green-700 md:w-[260px] py-[10px] px-4 rounded hover:bg-green-800 hover:shadow-lg flex justify-center items-center gap-2'
                onClick={() => setShowAddItemModal(true)}
                disabled={itemsForSelect.length <= 0}
              >
                <IconPlus /> {t('Select items to return')}
              </button>
            ) : null}
          </div>

          {!hideTotal && (
            <div
              className={`bg-white rounded font-medium text-[22px] flex items-center h-[42px] ${startValue ? 'pl-[40px] px-[60px] laptop:pl-[60px] laptop:pr-[62px]' : 'px-16 laptop:px-[62px]'} justify-center mb-6 md:mb-0`}
            >
              <div>{t('Total Offer Amount')}</div>
              <div className='ml-3'>
                {editTotalAmount ? (
                  <div className='relative'>
                    <label
                      htmlFor='input-edit_total_amount'
                      className='absolute top-2 left-[10px] text-zinc-400 text-base'
                    >
                      {getCurrencySymbol(appointmentInfo.intermediate_save?.currency || 'USD')}
                    </label>
                    <input
                      type='text'
                      name='edit_total_amount'
                      id='input-edit_total_amount'
                      value={totalAmount || ''}
                      onChange={(e) => {
                        var regex = /^\d+$/
                        if (e.target.value.match(regex) || e.target.value === '') {
                          setTotalAmount(+e.target.value)
                        }
                      }}
                      className={`form-input !h-[38px] ${isHongkong ? '!pl-12 !w-[110px]' : '!pl-8 !w-[100px]'} text-base font-normal`}
                    />
                  </div>
                ) : (
                  getCurrency(totalAmount, appointmentInfo.intermediate_save?.currency)
                )}
              </div>
              {editTotalAmount ? (
                <button type='button' className='px-3 py-3 group ml-2' onClick={() => onTotalAmountSave()}>
                  <IconDoneSimple classname='text-green-700 group-hover:text-green-900' />
                </button>
              ) : (
                <>
                  <button
                    type='button'
                    className='px-3 py-3 group ml-2 disabled:opacity-50 disabled:pointer-events-none'
                    onClick={() => setEditTotalAmount(true)}
                    disabled={!returnItems.length}
                    data-tooltip-id='button-edit_total_amount'
                  >
                    <IconEdit classname='text-zinc-500 group-hover:text-zinc-700' />
                  </button>
                  <Tooltip id='button-edit_total_amount' className={tooltipStyle} delayShow={tooltipDelay}>
                    {t('Edit total offer amount')}
                  </Tooltip>

                  {startValue && (
                    <>
                      <button
                        type='button'
                        className='px-3 py-3 group ml-2 disabled:opacity-50 disabled:pointer-events-none'
                        data-tooltip-id='button-undo_total_amount'
                        onClick={onTotalAmountUndo}
                      >
                        <IconUndo classname='text-zinc-500 group-hover:text-zinc-700' />
                      </button>
                      <Tooltip id='button-undo_total_amount' className={tooltipStyle} delayShow={tooltipDelay}>
                        {t('Undo total offer amount')}
                      </Tooltip>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        <div className='flex justify-between items-center mb-5 mt-8 md:mt-0 relative'>
          <DefaultButton
            title={`← ${t('Go Back')}`}
            onClick={onBack}
            classname='secondary_button min-w-[230px]'
            disabled={saveLoading}
          />

          <DefaultButton
            title={`${t('Save and Continue')} →`}
            onClick={onSave}
            classname='main_button min-w-[230px]'
            loading={saveLoading}
            disabled={saveLoading || !returnItems.length}
          />
        </div>
      </div>

      <AddConsignedReturnItemsModal
        showed={showAddItemModal}
        closeModal={() => setShowAddItemModal(false)}
        itemsForSelect={itemsForSelect}
      />

      <EditItemModal
        showed={!!itemEdit}
        closeModal={() => setItemEdit(null)}
        offerItem={itemEdit?.item || null}
        onItemSave={onItemEditSave}
        itemIndex={itemEdit?.index || 0}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        requiredFields={requiredFields}
        setOpenErrorModal={setOpenErrorModal}
      />

      <MessageModal
        opened={openErrorModal}
        title='Error'
        text={t('Please fill in all required fields')}
        onClose={() => setOpenErrorModal(false)}
        type='error'
      />
    </>
  )
}
