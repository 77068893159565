import React, { useState } from 'react'
import { Modal } from '../../modal'
import { IOfferItem } from '../../../types/offerTypes'
import { IconClose } from '../../../images/IconClose'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '../../../types/storeTypes'
import { useOfferItem } from '../../../hooks/useOfferItem'
import { getCurrency } from '../../../utils/getCurrency'
import { setConsignmentReturnItems } from '../../../store/consignmentReturnSlice'

interface Props {
  showed: boolean
  closeModal: () => void
  itemsForSelect: IOfferItem[]
}

export const AddConsignedReturnItemsModal: React.FC<Props> = ({ showed, closeModal, itemsForSelect }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const consignmentReturn = useSelector((store: IStore) => store.consignmentReturn)
  const { returnItems } = consignmentReturn
  const [selectedItems, setSelectedItems] = useState<IOfferItem[]>([])
  const { getOfferItemTitle } = useOfferItem()

  const setCheckbox = (item: IOfferItem) => {
    if (selectedItems.some((itm) => itm.id === item.id)) {
      setSelectedItems(selectedItems.filter((itm) => itm.id !== item.id))
    } else {
      setSelectedItems([...selectedItems, item])
    }
  }

  const onCancel = () => {
    setSelectedItems([])
    closeModal()
  }

  const onItemsSave = (items: IOfferItem[]) => {
    sessionStorage.removeItem('2_step_startValue')
    dispatch(setConsignmentReturnItems([...returnItems, ...items]))
  }

  const onSave = () => {
    onItemsSave(selectedItems)
    setSelectedItems([])
    closeModal()
  }

  return (
    <Modal showed={showed} bodyStyle='w-[1080px] !max-w-[95vw] xl:!max-w-[80vw] relative'>
      <div className='absolute top-3 right-5'>
        <button type='button' onClick={closeModal}>
          <IconClose />
        </button>
      </div>
      <div className='flex flex-col justify-between pt-4'>
        <div className='flex flex-col gap-6 h-[60vh] overflow-y-auto custom_scroll'>
          <div className='text-[28px] leading-9 font-medium text-zinc-800'>{t('Select items to return')}</div>

          <div className='flex flex-col gap-4'>
            {itemsForSelect.map((item) => {
              return (
                <div key={item.id} className='w-full flex items-center gap-2'>
                  <input
                    type='checkbox'
                    name={`item_for_copy-${item.id}`}
                    id={`input-item_for_copy-${item.id}`}
                    checked={selectedItems.some((itm) => itm.id === item.id)}
                    onChange={() => setCheckbox(item)}
                    className={`form-input w-[24px] h-[24px] accent-green-700`}
                    autoComplete='false'
                  />
                  <label htmlFor={`input-item_for_copy-${item.id}`} className='flex mt-1 text-lg text-zinc-800'>
                    {item.price ? getCurrency(item.price, consignmentReturn.currency) : null} {getOfferItemTitle(item)}
                  </label>
                </div>
              )
            })}
          </div>
        </div>

        <div className='flex flex-col justify-end md:flex-row gap-4 md:gap-8 w-full md:w-auto'>
          <button type='button' className='text_button !text-red-400' onClick={onCancel}>
            {t('Cancel')}
          </button>
          <button type='button' className='main_button w-full md:w-[200px]' onClick={onSave}>
            {t('Select')}
          </button>
        </div>
      </div>
    </Modal>
  )
}
