import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '../../../../types/storeTypes'
import { useGetStepData } from '../../../../hooks/useGetStepData'
import { useFormSession } from '../../../../hooks/useFormSession'
import { Loader } from '../../../loader'
import { IconEdit } from '../../../../images/IconEdit'
import { useOfferItem } from '../../../../hooks/useOfferItem'
import { getCurrency } from '../../../../utils/getCurrency'
import { IconNoData } from '../../../../images/IconNoData'
import { DefaultButton } from '../../../defaultButton'
import { AddItemStatusModal } from '../../../modals/addItemStatusModal'
import { IOfferItem } from '../../../../types/offerTypes'
import { setOfferItems } from '../../../../store/offerInfoSlice'
import moment from 'moment'
import { callApi } from '../../../../utils/callApi'
import { ApiRoutes } from '../../../../utils/apiRoutes'
import { showGlobalPopup } from '../../../../store/globalPopupSlice'
import { useOfferDocs } from '../../../../hooks/useOfferDocs'
import { EmailModal } from '../../../modals/emailModal'
import { ACQUIRED, CONSIGNMENT, DECLINED, FOLLOWUP, INELIGIBLE } from '../../../../utils/itemStatuses'
import { DecisionNotesModal } from '../../../modals/decisionNotesModal'
import { FORM_SUBMITTED } from '../../../../utils/consts'

export const ThirdStep = () => {
  const { t, i18n } = useTranslation()
  const offerInfo = useSelector((store: IStore) => store.offerInfo)
  const currentStep = useSelector((store: IStore) => store.currentStep)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const { offerItems, offerInfoUploaded } = offerInfo
  const { stepsLoading, changeCurrentStep, getSignatureStepAgreements, getSixthStepData } = useGetStepData()
  const dispatch = useDispatch()
  const { stopCheckSession, saveSession } = useFormSession()
  const { getOfferItemTitle, getOfferStatusBadge } = useOfferItem()
  const [saveLoading, setSaveLoading] = useState(false)
  const [editItemStatus, setEditItemStatus] = useState<IOfferItem | null>(null)
  const [sendMarketing, setSendMarketing] = useState(true)
  const [sendClient, setSendClient] = useState(true)
  const [sendThankYou, setSendThankYou] = useState(true)
  const [showFormInAccount, setShowFormInAccount] = useState(true)
  const [offerValidDate, setOfferValidDate] = useState('')
  const [followupDate, setFollowupDate] = useState('')
  const [notSetExpirationDate, setNotSetExpirationDate] = useState(false)
  const { loadPdf, loadPdfLoading } = useOfferDocs()
  const [showSendEmail, setShowSendEmail] = useState(false)
  const [showAddNote, setShowAddNote] = useState(false)
  const [decisionNotes, setDecisionNotes] = useState('')

  useEffect(() => {
    setFollowupDate(moment().add(1, 'days').format('yyyy-MM-DD'))
    const notesFromStorage = sessionStorage.getItem('decisionNotes')
    if (!decisionNotes && notesFromStorage) setDecisionNotes(notesFromStorage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showExpirationDate = useMemo(
    () => offerItems.some((item) => item.status && item.offer_made && [DECLINED, FOLLOWUP].includes(item.status)),
    [offerItems]
  )

  useEffect(() => {
    if (!offerValidDate && showExpirationDate) {
      setOfferValidDate(moment().add(7, 'days').format('yyyy-MM-DD'))
    }
  }, [showExpirationDate, offerValidDate])

  useEffect(() => {
    if (appointmentInfo.intermediate_save) {
      const data = appointmentInfo.intermediate_save
      if (data.send_marketing !== undefined) setSendMarketing(data.send_marketing)
      if (data.send_client_satisfaction !== undefined) setSendClient(data.send_client_satisfaction)
      if (data.send_thank_you !== undefined) setSendThankYou(data.send_thank_you)
      if (data.show_form_in_account !== undefined) setShowFormInAccount(data.show_form_in_account)
      if (data.decisionNotes !== undefined) setDecisionNotes(data.decisionNotes)
    }
  }, [appointmentInfo.intermediate_save])

  useEffect(() => {
    if (offerItems.length) {
      sessionStorage.setItem('3_step_items', JSON.stringify(offerItems))
    } else {
      sessionStorage.removeItem('3_step_items')
    }
  }, [offerItems])

  const onSave = async () => {
    setSaveLoading(true)
    stopCheckSession()

    try {
      const data = {
        items: offerItems.map((item) => {
          return {
            id: item.id,
            status: item.status,
            offer_made: item.offer_made,
            reason_decline: item.reason_decline,
            client_expectation: item.client_expectation,
            target_amount: item.target_amount,
          }
        }),
        send_marketing: sendMarketing,
        send_client_satisfaction: sendClient,
        send_thank_you: sendThankYou,
        expiration_date: !notSetExpirationDate && !allItemsIsGood && showExpirationDate ? offerValidDate : undefined,
        followup_date: followupDate && offerItems.some((item) => item.status === FOLLOWUP) ? followupDate : undefined,
        show_form_in_account: showFormInAccount,
      }
      const resp = await callApi({
        method: 'POST',
        url: `${ApiRoutes.thirdStep}`,
        data,
      })
      if (resp && resp.data) {
        const data = resp.data.data
        const nextStep = offerItems.some((item) => [ACQUIRED, CONSIGNMENT].includes(item.status || '')) ? 4 : 6
        await saveSession({
          currentStep: nextStep,
          offerItems: data.items,
          send_marketing: sendMarketing,
          send_client_satisfaction: sendClient,
          send_thank_you: sendThankYou,
          show_form_in_account: showFormInAccount,
          decisionNotes: decisionNotes ? decisionNotes : undefined,
        })
        sessionStorage.removeItem('3_step_items')
        sessionStorage.removeItem('decisionNotes')
        if (nextStep === 6) {
          try {
            await callApi({
              method: 'POST',
              url: `${ApiRoutes.formFinish}`,
            })
            const notes = decisionNotes
            sessionStorage.setItem(FORM_SUBMITTED, 'true')
            await getSixthStepData(notes)
            changeCurrentStep(nextStep)
          } catch (error) {
            console.log(error, 'error data')
            dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
          } finally {
            setSaveLoading(false)
          }
        } else {
          getSignatureStepAgreements()
          changeCurrentStep(nextStep)
        }
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setSaveLoading(false)
    }
  }

  const onBack = () => {
    changeCurrentStep(currentStep - 1)
  }

  const onItemsSave = (items: IOfferItem[]) => {
    dispatch(setOfferItems(items))
  }

  const purchaseAmount = useMemo(() => {
    return offerItems
      .filter((item) => item.offer_made)
      .reduce((a, b) => {
        if (b.status && [ACQUIRED].includes(b.status)) {
          return a + (b.price ? +b.price : 0)
        } else {
          return a + 0
        }
      }, 0)
  }, [offerItems])

  const offeredItemsAmount = useMemo(() => {
    return offerItems
      .filter((item) => item.offer_made)
      .reduce((a, b) => {
        if (b.status && ![ACQUIRED, CONSIGNMENT].includes(b.status)) {
          return a + (b.price ? +b.price : 0)
        } else {
          return a + 0
        }
      }, 0)
  }, [offerItems])

  const consignmentAmount = useMemo(() => {
    return offerItems
      .filter((item) => item.offer_made)
      .reduce((a, b) => {
        if (b.status && [CONSIGNMENT].includes(b.status)) {
          return a + (b.target_amount ? +b.target_amount : 0)
        } else {
          return a + 0
        }
      }, 0)
  }, [offerItems])

  const saveButtonTitle = offerItems.some((item) => [ACQUIRED, CONSIGNMENT].includes(item.status || ''))
    ? `${t('Save and Continue')} →`
    : t('Complete Appointment')

  const allItemsIsGood = useMemo(
    () => offerItems.every((item) => item.status && [ACQUIRED, CONSIGNMENT, INELIGIBLE].includes(item.status)),
    [offerItems]
  )

  const amountBlock = useMemo(() => {
    return (
      <div className='flex flex-col items-center md:items-end gap-2'>
        {purchaseAmount > 0 && (
          <div className='flex text-lg text-zinc-700 font-medium'>
            {t('Total Purchase Amount')}
            <span className='flex ml-2'>
              {getCurrency(purchaseAmount, appointmentInfo.intermediate_save?.currency)}
            </span>
          </div>
        )}
        {offeredItemsAmount > 0 && (
          <div className='flex text-lg text-zinc-700 font-medium'>
            {t('Open Offer Amount')}
            <span className='flex ml-2'>
              {getCurrency(offeredItemsAmount, appointmentInfo.intermediate_save?.currency)}
            </span>
          </div>
        )}
        {consignmentAmount > 0 && (
          <div className='flex text-lg text-zinc-700 font-medium'>
            {t('Consigned Amount')}
            <span className='flex ml-2'>
              {getCurrency(consignmentAmount, appointmentInfo.intermediate_save?.currency)}
            </span>
          </div>
        )}
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consignmentAmount, offeredItemsAmount, purchaseAmount, i18n.language])

  return stepsLoading.includes(3) || !offerInfoUploaded ? (
    <div className='w-full h-[70vh] flex justify-center items-center'>
      <Loader className='h-[100px]' />
    </div>
  ) : (
    <>
      <div className='flex flex-col'>
        {!!offerItems.length ? (
          <div className=''>
            <div className='text-base font-medium text-zinc-500 mb-2 flex justify-between px-3'>
              <div className=' w-[60%]'>
                <span className='mr-3'>#</span>
                {t('Item Descriptions')}
              </div>
              <div className='flex justify-end w-[100px] min-w-[60px]'>{t('Price')}</div>
              <div className='flex justify-end items-center w-[30%]'>{t('Status')}</div>
            </div>

            <div className=' rounded-lg bg-white flex flex-col divide-y divide-zinc-200 max-h-[50vh] overflow-y-auto custom_scroll'>
              {offerItems.map((item, index) => {
                return (
                  <div key={`${item.type}-${index}`} className=' text-zinc-700 flex justify-between items-center px-3'>
                    <div className='flex items-center w-[60%] min-h-[68px]'>
                      <div className='text-[22px] pb-1'>{index + 1}</div>
                      <div className=' text-base font-medium px-3 line-clamp-2'>{getOfferItemTitle(item)}</div>
                    </div>
                    <div className='flex'>
                      <div className='text-lg font-medium flex items-center justify-end w-[100px] min-w-[60px]'>
                        {item.status && item.status === CONSIGNMENT && item.target_amount
                          ? getCurrency(item.target_amount, appointmentInfo.intermediate_save?.currency)
                          : ''}
                        {item.status && item.status !== CONSIGNMENT && item.price && item.offer_made
                          ? getCurrency(item.price, appointmentInfo.intermediate_save?.currency)
                          : ''}
                      </div>
                    </div>

                    <div className='pl-6 w-[30%] min-w-[230px] flex justify-between py-3'>
                      <div className='flex gap-2 flex-wrap pr-2'>
                        <button type='button' onClick={() => setEditItemStatus(item)}>
                          {getOfferStatusBadge(item)}
                        </button>

                        {item.client_expectation ? (
                          <div className='px-[10px] py-[4px] text-sm text-zinc-700 bg-blue-100 rounded-[40px] w-fit'>
                            {t('Client Expectation')}:{' '}
                            {getCurrency(item.client_expectation, appointmentInfo.intermediate_save?.currency)}
                          </div>
                        ) : null}
                        {item.offer_made !== undefined && [DECLINED, INELIGIBLE].includes(item.status || '') ? (
                          <div className='px-[10px] py-[4px] text-sm text-zinc-700 bg-blue-100 rounded-[40px] w-fit'>
                            {t('Offer made')}: {item.offer_made ? t('Yes') : t('No')}
                          </div>
                        ) : null}
                        {item.reason_decline ? (
                          <div className='px-[10px] py-[4px] text-sm text-zinc-700 bg-blue-100 rounded-[40px] w-fit'>
                            {t('Reason')}: {t(item.reason_decline)}
                          </div>
                        ) : null}
                      </div>
                      <button
                        type='button'
                        className='group flex gap-2 items-center'
                        onClick={() => setEditItemStatus(item)}
                      >
                        {(!item.status || ['new-offer'].includes(item.status)) && (
                          <span className='text-red-400 text-sm'>{t('Set Status')}</span>
                        )}
                        <IconEdit
                          classname={`${!item.status || ['new-offer'].includes(item.status) ? 'text-red-400' : 'text-zinc-500'}  group-hover:text-zinc-700`}
                        />
                      </button>
                    </div>
                  </div>
                )
              })}
            </div>

            <div className='block mt-4 md:hidden'>{amountBlock}</div>

            <div className='flex justify-between gap-4 mt-6'>
              <div className='flex justify-start w-[130px]'>
                <div className='flex flex-col gap-1'>
                  <div className='w-full flex items-center gap-2'>
                    <input
                      type='checkbox'
                      name='send_marketing'
                      id='input-item-send_marketing'
                      checked={!!sendMarketing}
                      onChange={() => setSendMarketing(!sendMarketing)}
                      className={`form-input min-w-[16px] w-[16px] h-[16px] accent-green-700`}
                      autoComplete='false'
                    />
                    <label htmlFor='input-item-send_marketing' className='flex text-[15px] leading-5 text-zinc-800'>
                      {t('Send marketing')}
                    </label>
                  </div>
                  {/* <div className='w-full flex items-center gap-2'>
                    <input
                      type='checkbox'
                      name='send_client_satisfaction_survey'
                      id='input-item-send_client_satisfaction_survey'
                      checked={!!sendClient}
                      onChange={() => setSendClient(!sendClient)}
                      className={`form-input min-w-[16px] w-[16px] h-[16px] accent-green-700`}
                      autoComplete='false'
                    />
                    <label
                      htmlFor='input-item-send_client_satisfaction_survey'
                      className='flex text-[15px] leading-5 text-zinc-800'
                    >
                      {t('Send client satisfaction survey')}
                    </label>
                  </div> */}
                  <div className='w-full flex items-center gap-2'>
                    <input
                      type='checkbox'
                      name='send_thank_you'
                      id='input-item-send_thank_you'
                      checked={!!sendThankYou}
                      onChange={() => setSendThankYou(!sendThankYou)}
                      className={`form-input min-w-[16px] w-[16px] h-[16px] accent-green-700`}
                      autoComplete='false'
                    />
                    <label htmlFor='input-item-send_thank_you' className='flex text-[15px] leading-5 text-zinc-800'>
                      {t('Send thank you')}
                    </label>
                  </div>

                  <div className='w-full flex items-center gap-2 lg:hidden'>
                    <input
                      type='checkbox'
                      name='show_form_in_account-tablet'
                      id='input-item-show_form_in_account-tablet'
                      checked={!!showFormInAccount}
                      onChange={() => setShowFormInAccount(!showFormInAccount)}
                      className={`form-input min-w-[16px] w-[16px] h-[16px] accent-green-700`}
                      autoComplete='false'
                    />
                    <label
                      htmlFor='input-item-show_form_in_account-tablet'
                      className='flex text-[15px] leading-5 text-zinc-800'
                    >
                      {t(`Show purchase form in client's circajewels.com account`)}
                    </label>
                  </div>
                </div>
              </div>

              <div className='hidden md:block'>{amountBlock}</div>

              <div className='flex flex-col gap-4 items-start h-fit min-w-[160px] lg:min-w-[260px]'>
                {offerItems.some((item) => item.status && [DECLINED, FOLLOWUP].includes(item.status)) && (
                  <div className='flex flex-col w-full gap-3'>
                    {showExpirationDate && (
                      <div className='flex flex-col gap-2'>
                        <div className='flex flex-col lg:flex-row lg:items-center gap-1 lg:gap-4 w-full'>
                          <label
                            htmlFor='input-offer_valid_date'
                            className='flex text-base text-zinc-700 whitespace-nowrap w-[105px]'
                          >
                            {t('Offer Valid Until')}
                          </label>
                          <input
                            type='date'
                            name='offer_valid_date'
                            id='input-offer_valid_date'
                            value={offerValidDate}
                            onChange={(e) => {
                              if (e.target.value) {
                                setOfferValidDate(moment(e.target.value).format('yyyy-MM-DD'))
                              } else setOfferValidDate('')
                            }}
                            className='form-input !h-[38px] text-base !py-2'
                            autoComplete='false'
                          />
                        </div>
                        <div className='flex items-center gap-2'>
                          <div className='w-full flex items-center gap-2'>
                            <input
                              type='checkbox'
                              name='expiration_date'
                              id='input-item-expiration_date'
                              checked={!!notSetExpirationDate}
                              onChange={() => setNotSetExpirationDate(!notSetExpirationDate)}
                              className={`form-input min-w-[16px] w-[16px] h-[16px] accent-green-700`}
                              autoComplete='false'
                            />
                            <label
                              htmlFor='input-item-expiration_date'
                              className='flex text-[15px] leading-5 text-zinc-800'
                            >
                              {t(`Don't set an expiration date`)}
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {offerItems.some((item) => item.status === FOLLOWUP) && (
                      <div className='flex flex-col lg:flex-row lg:items-center h-fit gap-1 lg:gap-4 w-full'>
                        <label htmlFor='input-followup_date' className='flex text-base text-zinc-700 w-[105px]'>
                          {t('Followup')}
                        </label>
                        <input
                          type='date'
                          name='followup_date'
                          id='input-followup_date'
                          value={followupDate}
                          onChange={(e) => {
                            if (e.target.value) {
                              setFollowupDate(moment(e.target.value).format('yyyy-MM-DD'))
                            } else setFollowupDate('')
                          }}
                          className='form-input !h-[38px] text-base !py-2'
                          autoComplete='false'
                        />
                      </div>
                    )}

                    <button type='button' className='grey_button !w-full px-2' onClick={() => setShowAddNote(true)}>
                      <IconEdit />
                      <div className='flex gap-1'>
                        {decisionNotes ? t('Edit note') : t('Add note')}
                        <span className=' text-red-500'>({t('Visible to client')})</span>
                      </div>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className='w-full bg-white rounded-lg h-[50vh] overflow-y-auto custom_scroll flex justify-center items-center'>
            <div className='flex flex-col justify-center items-center'>
              <div className='text-[22px] text-zinc-800'>{t('Please add items')}</div>
              <IconNoData />
              <div className='text-lg text-zinc-400'>{t('No data')}</div>
            </div>
          </div>
        )}

        <div className='flex justify-between mb-5 mt-20 md:mt-12 relative'>
          <DefaultButton
            title={`← ${t('Go Back')}`}
            onClick={onBack}
            classname='secondary_button min-w-[200px]'
            disabled={saveLoading || loadPdfLoading}
          />

          <div className='flex flex-col gap-2 absolute top-[-60px] mx-auto w-full md:mx-0 md:w-auto md:top-0 md:relative'>
            <div className='flex items-center gap-4 w-full justify-center'>
              <DefaultButton
                title={t('Print')}
                onClick={() => loadPdf('decision', !notSetExpirationDate ? offerValidDate : undefined, decisionNotes)}
                classname='grey_button'
                loading={loadPdfLoading}
                disabled={
                  saveLoading || loadPdfLoading || (!offerValidDate && !notSetExpirationDate && !allItemsIsGood)
                }
                icon='print'
              />
              <DefaultButton
                title={t('Email')}
                onClick={() => setShowSendEmail(true)}
                classname='grey_button'
                disabled={
                  saveLoading || loadPdfLoading || (!offerValidDate && !notSetExpirationDate && !allItemsIsGood)
                }
                icon='email'
              />
            </div>
            <div className='justify-center hidden lg:flex'>
              <div className='flex items-center gap-2'>
                <input
                  type='checkbox'
                  name='show_form_in_account-desktop'
                  id='input-item-show_form_in_account-desktop'
                  checked={!!showFormInAccount}
                  onChange={() => setShowFormInAccount(!showFormInAccount)}
                  className={`form-input min-w-[16px] w-[16px] h-[16px] accent-green-700`}
                  autoComplete='false'
                />
                <label
                  htmlFor='input-item-show_form_in_account-desktop'
                  className='flex text-[15px] text-zinc-800 text-center'
                >
                  {t(`Show purchase form in client's circajewels.com account`)}
                </label>
              </div>
            </div>
          </div>
          <div className='[&>div]:h-12'>
            <DefaultButton
              title={saveButtonTitle}
              onClick={onSave}
              classname='main_button min-w-[200px]'
              loading={saveLoading}
              disabled={
                saveLoading || loadPdfLoading || offerItems.some((item) => !item.status || item.status === 'new-offer')
              }
            />
          </div>
        </div>
      </div>

      <AddItemStatusModal
        showed={!!editItemStatus}
        onItemsSave={onItemsSave}
        closeModal={() => setEditItemStatus(null)}
        startItem={editItemStatus || offerItems[0]}
      />
      <EmailModal
        showed={showSendEmail}
        closeModal={() => setShowSendEmail(false)}
        files={['offer']}
        offerValidDate={!notSetExpirationDate && !allItemsIsGood ? offerValidDate : undefined}
        decisionNotes={decisionNotes}
      />
      <DecisionNotesModal
        showed={showAddNote}
        closeModal={() => setShowAddNote(false)}
        note={decisionNotes}
        setNote={setDecisionNotes}
      />
    </>
  )
}
