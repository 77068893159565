import { IOfferItem } from '../types/offerTypes'
import { useSelector } from 'react-redux'
import { IStore } from '../types/storeTypes'
import { useTranslation } from 'react-i18next'
import { ACQUIRED, CONSIGNMENT, DECLINED, FOLLOWUP, INELIGIBLE } from '../utils/itemStatuses'

export const useOfferItem = () => {
  const offerDicts = useSelector((store: IStore) => store.offerDicts)
  const { t } = useTranslation()

  const getOfferItemTitle = (item: IOfferItem) => {
    let title = ''
    if (item.brand && offerDicts.offerDictsData) {
      const field = offerDicts.offerDictsData.brands.find((field) => field.id === item.brand)
      title += field ? `${field.name}` : ''
    }
    if (item.metal && offerDicts.offerDictsData) {
      const field = offerDicts.offerDictsData.metals.find((field) => field.id === item.metal)
      title += field ? ` ${field.name}` : ''
    }
    if (item.metal1 && offerDicts.offerDictsData) {
      const field = offerDicts.offerDictsData.metals.find((field) => field.id === item.metal1)
      title += field ? ` ${field.name}` : ''
    }
    if (item.metal2 && offerDicts.offerDictsData) {
      const field = offerDicts.offerDictsData.metals.find((field) => field.id === item.metal2)
      title += field ? ` ${field.name}` : ''
    }
    if (item.collection && offerDicts.offerDictsData) {
      const field = offerDicts.offerDictsData.models.find((field) => field.id === item.collection)
      title += field ? ` ${field.name}` : ''
    }
    if (item.model && offerDicts.offerDictsData) {
      const field = offerDicts.offerDictsData.models.find((field) => field.id === item.model)
      title += field ? ` ${field.name}` : ''
    }
    if (item.cert_type) {
      title += ` ${item.cert_type}`
    }
    if (item.cert_number) {
      title += ` ${item.cert_number}`
    }
    if (item.shape) {
      title += ` ${item.shape}`
    }
    if (item.carat_weight) {
      title += ` ${item.carat_weight}`
    }
    if (item.color_grade) {
      title += ` ${item.color_grade}`
    }
    if (item.clarity_grade) {
      title += ` ${item.clarity_grade}`
    }
    if (item.cut) {
      title += ` ${item.cut}`
    }
    if (item.polish) {
      title += ` ${item.polish}`
    }
    if (item.symmetry) {
      title += ` ${item.symmetry}`
    }
    if (item.fluorescence) {
      title += ` ${item.fluorescence}`
    }
    if (item.diamond && offerDicts.offerDictsData) {
      const field = offerDicts.offerDictsData.diamonds.find((field) => field.id === item.diamond)
      title += field ? ` ${field.name}` : ''
    }
    if (item.primary_gem && offerDicts.offerDictsData) {
      const field = offerDicts.offerDictsData.gems.find((field) => field.id === item.primary_gem)
      title += field ? ` ${field.name}` : ''
    }
    if (item.subproduct && offerDicts.offerDictsData && !['scrap', 'intrinsic', 'melee'].includes(item.type)) {
      const field = offerDicts.offerDictsData.subproducts.find((field) => field.id === item.subproduct)
      title += field ? ` ${field.name}` : ''
    }
    if (item.model_number) {
      title += `, ${t('Model')} # ${item.model_number}`
    }
    if (item.serial_number) {
      title += `${item.model_number ? '' : ','} ${t('Serial')} # ${item.serial_number}`
    }
    if (item.year_type && item.year_type !== 'exact') {
      title += `, ${item.year_type[0].toUpperCase() + item.year_type.slice(1)}`
    }
    if (item.full_year) {
      title += `${item.year_type && item.year_type !== 'exact' ? '' : ','} ${item.full_year}`
    }
    if (item.box || item.papers) {
      const str = `${item.box ? t('Box') : ''}${item.box && item.papers ? ' & ' : ''}${item.papers ? t('Papers') : ''} ${t('Included')}`
      title += `, ${str}`
    }
    if (item.additional_description) {
      title += `${['scrap', 'intrinsic', 'melee', 'other'].includes(item.type) && !item.metal1 && !item.metal2 ? '' : ','} ${item.additional_description[0].toUpperCase() + item.additional_description.slice(1)}`
    }

    // {{Brand}} {{Metal1}} {{Metal2}} {{Collection}} {{Model}} {{Report}} {{Report No}} {{Shape}} {{carat weight}} {{color}} {{clarity}} {{cut}} {{polish}} {{symmetry}} {{fluorescence}} {{Diamond}} {{Primary Gem}} {{Subproduct}}, {{Model #}} {{Serial #}} {{year_type}} {{full_year}}, {{Box,Papers,Box & Papers Included}}, {{Additional Description}}
    return title
  }

  const getOfferStatusBadge = (item: IOfferItem) => {
    const classname = 'px-[10px] py-[4px] text-sm text-zinc-900 rounded-[40px] w-fit'
    switch (item.status) {
      case ACQUIRED:
        return <div className={`${classname} bg-green-100`}>{t('Acquired')}</div>
      case DECLINED:
        return <div className={`${classname} bg-red-100`}>{t('Declined')}</div>
      case INELIGIBLE:
        return <div className={`${classname} bg-red-100`}>{t('Ineligible')}</div>
      case CONSIGNMENT:
        return <div className={`${classname} bg-orange-100`}>{t('Consignment')}</div>
      case FOLLOWUP:
        return <div className={`${classname} bg-yellow-100`}>{t('Followup')}</div>

      default:
        return <div className={`${classname} bg-zinc-100`}>{t('No status')}</div>
    }
  }
  return {
    getOfferItemTitle,
    getOfferStatusBadge,
  }
}
