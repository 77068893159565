import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['consignmentReturn'] = {
  consignment_acquired_amount: '',
  currency: 'USD',
  id: 0,
  internal_notes: [],
  status: '',
  items: [],
  returnItems: [],
}

export const consignmentReturnSlice = createSlice({
  name: 'consignmentReturn',
  initialState,
  reducers: {
    setConsignmentReturnInfo: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setConsignmentReturnItems: (state, action) => {
      return {
        ...state,
        returnItems: action.payload,
      }
    },
    setConsignmentReturnNotes: (state, action) => {
      return {
        ...state,
        internal_notes: action.payload,
      }
    },
    addConsignmentReturnInternalNote: (state, action) => {
      return {
        ...state,
        internal_notes: [...state.internal_notes, action.payload],
      }
    },
    deleteConsignmentReturnNote: (state, action) => {
      return {
        ...state,
        internal_notes: state.internal_notes.filter((note) => note.id !== action.payload.id),
      }
    },
  },
})

export const {
  setConsignmentReturnInfo,
  setConsignmentReturnItems,
  setConsignmentReturnNotes,
  addConsignmentReturnInternalNote,
  deleteConsignmentReturnNote,
} = consignmentReturnSlice.actions

export default consignmentReturnSlice.reducer
