import moment from 'moment'

export const API_URL = process.env.REACT_APP_API_PATH
export const LOCAL_ACCESS_TOKEN = 'ACCESS_TOKEN'
export const SESSION_TOKEN = 'SESSION_TOKEN'
export const FORM_SUBMITTED = 'FORM_SUBMITTED'
export const fileStackApi = 'A3r4cONw2QCeRFN4kcWd5z'
export const serverMode = process.env.REACT_APP_ENV || 'development'
export const fileStackSource = ['local_file_system', 'googledrive', 'googlephotos', 'dropbox']
export const minWatchYear = 1500
export const currentYear = moment().year()

export const plaidConfig = {
  clientName: 'user_custom',
  env:
    // eslint-disable-next-line no-nested-ternary
    process.env.GATSBY_ACTIVE_ENV === 'prod'
      ? 'production'
      : // : process.env.GATSBY_ACTIVE_ENV === 'staging'
        // ? 'development'
        'sandbox',
  // product: ['auth', 'transactions'],
  product: ['auth'],
  publicKey: 'b04015b58982f74bc008f3b5b533ba',
  // override_username: 'user_custom',
  // override_password: JSON.stringify({
  //   override_accounts: [
  //     {
  //       starting_balance: 10000,
  //       type: 'depository',
  //       subtype: 'checking',
  //       meta: {
  //         name: 'Checking Name 1',
  //       },
  //     },
  //     {
  //       starting_balance: 20000,
  //       type: 'depository',
  //       subtype: 'savings',
  //       meta: {
  //         name: 'Savings Name 2',
  //       },
  //     },
  //   ],
  // }),
  // customizations: {
  //   username: 'user_custom',
  //   password: 'pass_custom',
  // },
  // user: {
  //   username: 'user_custom',
  //   password: 'pass_custom',
  // },
}
