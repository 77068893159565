import React, { useEffect, useMemo, useState } from 'react'
import { Loader } from '../components/loader'
import { useSelector } from 'react-redux'
import { IStore } from '../types/storeTypes'
import { CloseFormModal } from '../components/modals/closeFormModal'
import { useTranslation } from 'react-i18next'
import { AgreementsWarnModal } from '../components/modals/agreementsWarnModal'
import { FORM_SUBMITTED, LOCAL_ACCESS_TOKEN } from '../utils/consts'
import { useGetConsignedReturnStepData } from '../hooks/useGetConsignedReturnStepData'
import { FirsStep } from '../components/consignment-return/steps/firstStep'
import { SecondStep } from '../components/consignment-return/steps/secondStep'
import { SignatureStep } from '../components/consignment-return/steps/signatureStep'
import { ConfirmationStep } from '../components/consignment-return/steps/confirmationStep'
import { StepBar } from '../components/consignment-return/stepBap'

export const ConsignmentReturn = () => {
  const { t, i18n } = useTranslation()
  const [stepLoading, setStepLoading] = useState(true)
  const {
    getStartData,
    getSecondStepData,
    changeCurrentStep,
    getSignatureStepAgreements,
    getConfirmationStepData,
    getSessionData,
    sessionLoading,
  } = useGetConsignedReturnStepData()
  const currentStep = useSelector((store: IStore) => store.currentStep)
  const sessionLoadingError = useSelector((store: IStore) => store.sessionLoadingError)
  const offerDicts = useSelector((store: IStore) => store.offerDicts)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const authorized = !!sessionStorage.getItem(LOCAL_ACCESS_TOKEN)
  const formSubmitted = sessionStorage.getItem(FORM_SUBMITTED) === 'true'

  useEffect(() => {
    if (authorized && !formSubmitted) {
      getSessionData(async (step, data) => {
        if (step === 1) {
          await getStartData()
        }
        if (step === 2) {
          await getSecondStepData()
          changeCurrentStep(2, data)
        }
        if (step === 3) {
          await getSignatureStepAgreements()
          changeCurrentStep(3, data)
        }
        if (step === 4) {
          const items =
            data?.consignmentConversion && data.consignmentConversion.conversionItems
              ? data?.consignmentConversion.conversionItems
              : []
          await getConfirmationStepData(undefined, items)
          changeCurrentStep(5)
        }
        setStepLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorized, formSubmitted])

  useEffect(() => {
    if (
      (!!appointmentInfo.appointment?.id && !appointmentInfo.intermediate_save?.appointment_id) ||
      (!!appointmentInfo.intermediate_save?.appointment_id && !appointmentInfo.appointment?.id)
    ) {
      const apptId = appointmentInfo.appointment?.id || appointmentInfo.intermediate_save?.appointment_id
      const userId = appointmentInfo.appointment?.client.id || appointmentInfo.intermediate_save?.client_id
      // @ts-ignore
      if (window && window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
          event: 'flow_start',
          appointmentId: apptId,
          userId: userId,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentInfo.appointment?.id, appointmentInfo.intermediate_save?.appointment_id, currentStep])

  useEffect(() => {
    if (offerDicts.language !== i18n.language && offerDicts.offerDictsUploaded) {
      getSecondStepData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  const getStep = useMemo(() => {
    switch (currentStep) {
      case 1:
        return <FirsStep />
      case 2:
        return <SecondStep />
      case 3:
        return <SignatureStep />
      case 4:
        return <ConfirmationStep />

      default:
        return <SecondStep />
    }
  }, [currentStep])

  return sessionLoadingError || (formSubmitted && currentStep <= 1) ? (
    <div className='flex h-[70vh] justify-center items-center text-center'>
      <div className='max-w-[500px] font-medium text-xl'>
        {formSubmitted
          ? t('Your Appointment Completed.')
          : t(
              'Your session has been expired. Please return back to the Appointment card in Admin and click on Continue appointment. If Continue appointment button is not there, reopen the appointment card.'
            )}
      </div>
    </div>
  ) : (
    <div className='flex flex-col gap-6'>
      {stepLoading || sessionLoading ? (
        <div className='w-full h-[70vh] flex justify-center items-center'>
          <Loader className='h-[100px]' />
        </div>
      ) : (
        <>
          <StepBar />

          <div className='step_content'>{getStep}</div>
        </>
      )}
      <CloseFormModal />
      <AgreementsWarnModal isConsignedConvert />
    </div>
  )
}
