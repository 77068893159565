import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['consignmentConversion'] = {
  consignment_acquired_amount: '',
  currency: 'USD',
  id: 0,
  internal_notes: [],
  status: '',
  items: [],
  conversionItems: [],
}

export const consignmentConversionSlice = createSlice({
  name: 'consignmentConversion',
  initialState,
  reducers: {
    setConsignmentConversionInfo: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setConsignmentConversionItems: (state, action) => {
      return {
        ...state,
        conversionItems: action.payload,
      }
    },
    setConsignmentConversionNotes: (state, action) => {
      return {
        ...state,
        internal_notes: action.payload,
      }
    },
    addConsignmentConversionInternalNote: (state, action) => {
      return {
        ...state,
        internal_notes: [...state.internal_notes, action.payload],
      }
    },
    deleteConsignmentConversionNote: (state, action) => {
      return {
        ...state,
        internal_notes: state.internal_notes.filter((note) => note.id !== action.payload.id),
      }
    },
  },
})

export const {
  setConsignmentConversionInfo,
  setConsignmentConversionItems,
  setConsignmentConversionNotes,
  addConsignmentConversionInternalNote,
  deleteConsignmentConversionNote,
} = consignmentConversionSlice.actions

export default consignmentConversionSlice.reducer
