import { useState } from 'react'
import { ApiRoutes } from '../utils/apiRoutes'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showGlobalPopup } from '../store/globalPopupSlice'
import { callApi } from '../utils/callApi'

export interface IReturnInProgressData {
  items: number[]
}

export const useConsignedReturn = () => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const [itemsReturnLoading, setItemsReturnLoading] = useState(false)

  const setReturnInProgress = async (data: IReturnInProgressData, cb?: () => void) => {
    setItemsReturnLoading(true)
    try {
      const resp = await callApi({
        method: 'PUT',
        url: `${ApiRoutes.process}?lang=${i18n.language}`,
        isConsignedReturn: true,
        data,
      })
      // @ts-ignore
      if (resp && resp.data && resp.data.success) {
        if (cb) cb()
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setItemsReturnLoading(false)
    }
  }

  return {
    setReturnInProgress,
    itemsReturnLoading,
  }
}
