import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormSession } from './useFormSession'
import { callApi } from '../utils/callApi'
import { ApiRoutes } from '../utils/apiRoutes'
import { showGlobalPopup } from '../store/globalPopupSlice'
import { IRelatedPerson } from '../types/additionalTypes'

export interface IUploadRelatedClientGDPRData {
  first_name: string
  last_name: string
  email: string
  phone: string
  signature: string
  send_marketing: boolean
  country: string
  city: string
  address1: string
  address2: string
  postal_code: string
  id?: number
}

export const useRelatedClient = () => {
  const dispatch = useDispatch()
  const { startCheckSession, stopCheckSession } = useFormSession()
  const [uploadRelatedClientGDPRLoading, setUploadRelatedClientGDPRLoading] = useState(false)
  const [searchLoading, setSearchLoadingLoading] = useState(false)
  const [relatedClientLoading, setRelatedClientLoading] = useState(false)
  // const [deleteGDPRLoading, setDeleteGDPRLoading] = useState(false)

  const uploadRelatedClientGDPR = async (data: IUploadRelatedClientGDPRData, cb?: (value: string) => void) => {
    stopCheckSession()
    setUploadRelatedClientGDPRLoading(true)
    try {
      const resp = await callApi({
        method: 'POST',
        url: `${ApiRoutes.relatedClientGdpr}`,
        data,
        isPurchaseForm: true,
      })

      if (resp && resp.data && resp.data.success) {
        const url = resp.data.url
        if (cb) cb(url)
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setUploadRelatedClientGDPRLoading(false)
      startCheckSession()
    }
  }

  const getRelatedCLients = async (query: string, cb?: (value: IRelatedPerson[]) => void) => {
    stopCheckSession()
    setSearchLoadingLoading(true)
    try {
      const resp = await callApi({
        method: 'GET',
        url: `${ApiRoutes.relatedClientSearch}?q=${query}`,
        isPurchaseForm: true,
      })

      if (resp && resp.data) {
        const data = resp.data
        if (cb) cb(data)
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setSearchLoadingLoading(false)
      startCheckSession()
    }
  }

  const createRelatedClient = async (data: any, cb?: (value: IRelatedPerson) => void) => {
    stopCheckSession()
    setRelatedClientLoading(true)
    try {
      const resp = await callApi({
        method: 'POST',
        url: `${ApiRoutes.relatedClient}`,
        data,
        isPurchaseForm: true,
      })

      if (resp && resp.data) {
        if (cb) {
          await bindRelatedClient(resp.data.id)
          cb(resp.data)
        }
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setRelatedClientLoading(false)
      startCheckSession()
    }
  }

  const updateRelatedClient = async (data: any, cb?: (value: IRelatedPerson) => void) => {
    stopCheckSession()
    setRelatedClientLoading(true)
    try {
      const resp = await callApi({
        method: 'PUT',
        url: `${ApiRoutes.relatedClient}`,
        data,
        isPurchaseForm: true,
      })

      if (resp && resp.data) {
        if (cb) {
          await bindRelatedClient(resp.data.id)
          cb(resp.data)
        }
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setRelatedClientLoading(false)
      startCheckSession()
    }
  }

  const bindRelatedClient = async (id: number, cb?: (value: string) => void) => {
    try {
      const resp = await callApi({
        method: 'POST',
        url: `${ApiRoutes.relatedClientBind}`,
        data: {
          client_id: id,
        },
        isPurchaseForm: true,
      })

      if (resp && resp.data) {
        if (cb) cb(resp.data)
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
    }
  }

  // const deleteGDPR = async (cb?: () => void) => {
  //   stopCheckSession()
  //   setDeleteGDPRLoading(true)
  //   try {
  //     const resp = await callApi({
  //       method: 'DELETE',
  //       url: `${ApiRoutes.relatedClientGdpr}`,
  //     })
  //     if (resp && resp.data && resp.data.success) {
  //       if (cb) cb()
  //     }
  //   } catch (error) {
  //     console.log(error, 'error data')
  //     dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
  //   } finally {
  //     setDeleteGDPRLoading(false)
  //     startCheckSession()
  //   }
  // }

  return {
    uploadRelatedClientGDPR,
    // deleteGDPR,
    uploadRelatedClientGDPRLoading,
    // deleteGDPRLoading,
    searchLoading,
    getRelatedCLients,
    createRelatedClient,
    updateRelatedClient,
    relatedClientLoading,
    bindRelatedClient,
  }
}
